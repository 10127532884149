import { useEffect, useState, React } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AUTH from "../../../../services/auth-service";
import API from "../../../../services/api-service";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MenuItem from "@mui/material/MenuItem";

const columns = [
    { id: 'name', label: 'Variable', minWidth: 170 },
    { id: 'code', label: 'Fecha de Creación', minWidth: 100 },
    { id: 'code', label: 'Valor', minWidth: 100 },
    { id: 'code', label: ' ', minWidth: 5 }

];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #ffffff',
    borderRadius: 5, // Puedes ajustar el valor según sea necesario
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.2)', // Corregí la sintaxis de boxShadow
    p: 4,
};

export default function StickyHeadTable(props) {

    const [dataTabla, setDataTabla] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { rows } = props
    const [rowsTable, setRowsTable] = useState(rows)
    const query = new URLSearchParams(useLocation().search);
    const organizacionn = query.get("organizacion");
    const localizacionn = query.get("localizacion");
    const deviceID = query.get("deviceID");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [dataActualizar, setDataActualizar] = useState({})
    const [dataNombreNuevo, setDataNombreNuevo] = useState(dataActualizar.operacion)
    const [dataOperacionNuevo, setDataOperacionNuevo] = useState(dataActualizar.nombreVariable)
    const [varID, setVarID] = useState()
    const [select, setSelect] = useState("")
    const [textoV, setTextoV] = useState("");
    const [trues, setTrue] = useState(false);
    const [textoW, setTextoW] = useState(dataActualizar.operacion);
    const [soloVariable, setSoloVariables] = useState({})
    const [actualData, setActualData] = useState("")

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const botonActualizar = () => {
        let datass = { ...dataActualizar }
        datass = {
            organizacion: organizacionn,
            localizacion: localizacionn,
            deviceID: deviceID,
            _id: varID,
            nombreVariable: dataNombreNuevo,
            operacion: textoW
        }
        try {
            API.actualizacionVariableSintetica(datass).then((respuesta) => {
                console.log(respuesta, 'respuesta');

            })
            reloadTable()
            Swal.fire({ title: "Variable actualizada", icon: "success", confirmButtonColor: "#1976d2", })
            handleClose()
            setTrue(!true)
        } catch (error) {
            console.error(error, 'errorrrrrrrrr');
        }
    }

    const probandoLog = (value) => {
        console.log("Aqui llega");
        console.log(value);
        setSelect(value)
        let asd = `@/${value}/@`
        let testinggg = textoV + asd
        // setTextoV(testinggg)
        setTextoV(testinggg)
        setTextoW(testinggg)
        setDataOperacionNuevo(testinggg)
        let textfin = textoV + value
        console.log(textfin, '-------------');
        setActualData(dataActualizar.operacion)
    }

    const funcionTexto = (value) => {
        setTextoV(value)
        setTextoW(value)
    }

    const handleKeyDown = (event) => {
        const teclasPermitidasRegex = /^[+\-*/\s1234567890.()@]|Backspace$|^Arrow(Up|Down|Left|Right)$/;
        if (!teclasPermitidasRegex.test(event.key)) {
            event.preventDefault();
        }
    };

    const reloadTable = async () => {
        let Data = {
            organizacion: organizacionn,
            localizacion: localizacionn,
            deviceID: deviceID
        };
        try {
            const response = await API.obtenerTablaVariables(Data);

            setRowsTable(response)
        } catch (error) {
            console.error('Error al obtener la tabla de variables:', error);
        }
    }
    const actualizarVariable = (rowId, rowNombre, rowOperacion) => {
        const recibirDevicee = AUTH.getVariablesSinteticas()
        let arregloDefinitivo = []

        recibirDevicee.variables.map((index, _) => {
            let documento = {}
            documento.label = index
            documento.value = index
            arregloDefinitivo.push(documento)
        })
        setSoloVariables(arregloDefinitivo)
        setSelect(recibirDevicee)
        Swal.fire({
            title: "Confirmar operación",
            text: "Seguro que desea actualizar la Variable Sintética?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#1976d2",
            cancelButtonColor: "#d33",

        }).then((event) => {
            if (event.isConfirmed) {
                try {
                    let newData = {
                        organizacion: organizacionn,
                        localizacion: localizacionn,
                        deviceID: deviceID,
                        _id: rowId,
                        nombreVariable: rowNombre,
                        operacion: rowOperacion
                    }
                    handleOpen()
                    setDataActualizar(newData)
                    setDataNombreNuevo(rowNombre)
                    setDataOperacionNuevo(rowOperacion)
                } catch (error) {
                    console.error(error);
                }
            }
        });
    }

    const deleteVariable = (deviceID) => (event) => {
        event.preventDefault();
        Swal.fire({
            title: "Confirmar operación",
            text: "Seguro que desea eliminar la Variable Sintética?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#1976d2",
            cancelButtonColor: "#d33",

        }).then((event) => {
            if (event.isConfirmed) {
                API.eliminarVariableSintetica(deviceID, organizacionn, localizacionn)
                    .then((respuesta) => {
                        Swal.fire({ title: "Variable eliminada", icon: "success", confirmButtonColor: "#1976d2", }).then(() => {
                            console.log(deviceID, organizacionn, localizacionn, 'ELIMINANDO1');
                            console.log(respuesta, 'RESPUESTAELIMINANDO2');
                        });
                        const newRowsTable = rowsTable.filter((rowId) => rowId._id !== deviceID)
                        setRowsTable(newRowsTable)
                    })
            }
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        console.log(props, 'propsFromViewOne');
        setDataTabla(props)
        setRowsTable(props.rows)
    }, [props.rows, trues])

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsTable
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((row) => (
                                    <TableRow>
                                        <TableCell>
                                            {row.nombreVariable}
                                        </TableCell>
                                        <TableCell>
                                            {row.fechaCompleta.substring(0, 10)}
                                        </TableCell>
                                        <TableCell>
                                            {row.operacion}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                onClick={(e) => {
                                                    actualizarVariable(row._id, row.nombreVariable, row.operacion)
                                                    setVarID(row._id)
                                                }}
                                            >
                                                <EditIcon />
                                            </Button>
                                            <Button variant="outlined" color="error" onClick={deleteVariable(row._id)}><DeleteForeverIcon /></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <>
                {open ? <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Actualizar datos de Variable Sintética
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '30ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="outlined-basic"
                                        label="Nombre Dispositivo"
                                        variant="outlined"
                                        value={dataNombreNuevo}
                                        defaultValue={dataActualizar.nombreVariable}
                                        onChange={(event) => {
                                            setDataNombreNuevo(event.target.value)
                                            console.log(dataNombreNuevo, '.....');
                                        }}
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Variables del Dispositivo"
                                        value={select}
                                        onChange={(e) => probandoLog(e.target.value)}

                                    >
                                        {soloVariable.slice(6).map((option) => (
                                            <MenuItem key={option.label} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Variables"
                                        multiline
                                        rows={4}
                                        defaultValue={dataActualizar.operacion}
                                        value={textoW}
                                        onChange={(event) => { funcionTexto(event.target.value) }}
                                        // onChange={(event) => { setDataOperacionNuevo(event.target.value) }}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <Stack direction="row" spacing={2}>
                                        <Button variant="outlined" onClick={botonActualizar}>Actualizar</Button>
                                        <Button variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
                                    </Stack>
                                </Box>
                            </Typography>
                        </Box>
                    </Modal>
                </div> : <></>}
            </>
        </>
    );
}