import React, { useEffect, useState } from "react";
import Api from "../../../services/api-service";
import Input2 from "../../../components/Entradas/Input2/index2";
import SearchIcon from "@material-ui/icons/Search";
import InputBusqueda from "../../../components/Table/inputBusqueda";
import Button from "../../../components/Button/index";
import Auth from "../../../services/auth-service.js";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Check from "../../../assets/img/check.png"
import Swal from "sweetalert2";
import {
  BarraBusqueda,
  BotonCrear,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Tiempo,
  Fecha,
  BotonMostrar,
  BotonDatos,
  BotonDevice,
  BotonRetroceder,
  BotonSiguiente,
  Br,
} from "../AlertasElements";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "../../../components/Entradas/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const Index = () => {
  /*Dev lenin*/
  const [selTodos, setSelecTodos] = useState(null);
  const [nombreAlerta, setNombreAlerta] = useState("");
  const [postUnico, setUnico] = useState(null);
  const [postControl, setControll] = useState(false);
  const [postData, setDataa] = useState([]);
  const [valorOrganizacion, setValorOrganizacion] = useState();
  const [valorObjetvo, setValorObjeto] = useState([]);
  const [variablesPost, setVariablesPost] = useState([]);

  let history = useHistory();
  /* FIN*/

  const [arregloDispo, setArregloDispo] = useState([]);

  const [postOrganizaciones, setOrganizaciones] = useState([]);
  const [postHabilitaciones, setHabilitaciones] = useState([]);
  const [data, setData] = useState([]);
  const [newA, setNewA] = useState([]);
  const [nsd, setsd] = useState([]);
  const [postRol, setRol] = useState("");
  const [contro, setControl] = useState(false);
  const [selec, setSelec] = useState([true, false]);
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const obteLocalizacion = query.get("localizacion");

  function seleccionarTodos(e) {
    ////console.log("TODSSSSSSSSSSSSSSSSSSSSSSSS")
    if (e.target.checked) {
      setSelecTodos(true)
    } else {
      setSelecTodos(false)
    }

  }

  function addDevices(e, i) {
    if (e.target.checked) {
      let as = []
      ////console.log("ESTO ESSSSSSSSSS")
      ////console.log(e.target.checked)
      ////console.log(i)
      arregloDispo.push(i)
      setArregloDispo(arregloDispo)
      ////console.log("valoreeeeeeeeeeeessssssss")
      ////console.log(arregloDispo)
      // const resultado = arregloDispo.push(i)
      // setArregloDispo(resultado)
    } else {
      const resultado = arregloDispo.filter(numbers => numbers != i);
      setArregloDispo(resultado)
    }

    // ////console.log("ESTO ESSSSSSSSSS")
    // ////console.log(e.target.checked)
    // ////console.log(i)
  }

  //console.log("first")
  //console.log("first")
  //console.log("first")
  //console.log(valorOrganizacion)
  //console.log("first")
  //console.log("first")
  //console.log("first")
  //console.log("first")
  function envioParametros(e) {
    let arreglo = [];
    let Datos = {};
    setValorOrganizacion(e);
    postData.map((index) => {
      if (index.organizacion == e) {
        index.configuracion.map((index) => {
          if (
            index.catalogo != null &&
            index.catalogo != undefined &&
            index.catalogo != ""
          ) {
            ////console.log(index.catalogo);
            Datos = {};
            Datos.value = index.catalogo;
            Datos.label = index.nombre;
            arreglo.push(Datos);
            setControll(true);
          }
        });
        setValorObjeto(arreglo);
      }
    });
  }

  useEffect(async () => {
    Auth.saveAlert({})

    // setOrganizaciones(Auth.getOrganizaciones());

    let cotroles = await Auth.getOrganizaciones();
    ////console.log(cotroles.length);
    ////console.log(cotroles);
    if (cotroles.length == 1) {
      let arreglo = [];
      let Datos = {};
      ////console.log("Seteando organizacion");
      ////console.log(cotroles[0].organizacion);
      setUnico(cotroles[0].organizacion);
      setValorOrganizacion(cotroles[0].organizacion);
      cotroles[0].localizacion[0].configuraciones.map((index) => {
        if (
          index.catalogo != null &&
          index.catalogo != undefined &&
          index.catalogo != ""
        ) {
          ////console.log(index.catalogo);
          Datos = {};
          Datos.value = index.catalogo;
          Datos.label = index.nombre;
          arreglo.push(Datos);
          setControll(true);
        }
      });
      setValorObjeto(arreglo);
    } else {
      setDataa(cotroles);
    }

    ////console.log(Auth.getHabilitations2());
    // setHabilitaciones(Auth.getHabilitations2());

    // let kobteOrganizacion = "epconst_gad_sto";
    let kobteOrganizacion = valorOrganizacion;
    Api.obtenerTESTING(postRol, kobteOrganizacion, obteLocalizacion).then(
      (respuesta) => {
        //console.log("----------------------------------------------")
        //console.log(respuesta);
        if (respuesta.status == true) {
          setData("");
        } else {
          console.log("Obtencion de datos")
          console.log(respuesta)
          setData(respuesta[0].dispositivos);
          setVariablesPost(respuesta[0].keys)
          ////console.log(data);
        }
      },
      (error) => { }
    );
  }, [postRol, selTodos]);

  function sendData() {
    let expre = /^[\w\s-_()]*$/

    let DOCUMENTO = {
      "nombre": "",
      "modulo": "",
      "mensajeTriiger": "",
      "messageThen": "l",
      "estado": true,
      "stateRepeat": false,
      "totalMinutes": 0,
      "totalMessage": 5,
      "condicionales": [],
      "connectors": [],
      "phones": [],
      "mails": [],
      "dispositivos": [],
      "organizacion": ""
    }
    if (!selTodos && arregloDispo.length <= 0) {
      Swal.fire("Debe Seleccionar algún dispositivo", "", "error");
    } else if (nombreAlerta == "") {
      Swal.fire("Debe Agregar un nombre", "", "error");
    }
    else {
      Auth.saveVariables(variablesPost)
      if (selTodos) {
        let dispositivosFinales = []
        data.map((index) => {
          let documento = {}
          documento.deviceID = index.dispositivo
          documento.auxwaitTrigger = ""
          documento.auxMessage = 0
          documento.stateNotified = true

          // dispositivosFinales.push(index.dispositivo)
          dispositivosFinales.push(documento)
        })
        ////console.log("Dispositivos finalesssssssssssssssssss")
        ////console.log(dispositivosFinales)

        DOCUMENTO.dispositivos = dispositivosFinales
        DOCUMENTO.modulo = postRol
        DOCUMENTO.organizacion = valorOrganizacion
        DOCUMENTO.nombre = nombreAlerta
        Auth.saveAlert(DOCUMENTO)
        Swal.fire("Guardado exitosamente", "", "success");
      } else {
        let dispositivosFinales2 = []
        arregloDispo.map((index) => {
          let documento = {}
          documento.deviceID = index
          documento.auxwaitTrigger = ""
          documento.auxMessage = 0
          documento.stateNotified = true
          // dispositivosFinales2.push(index.dispositivo)
          dispositivosFinales2.push(documento)
        })
        ////console.log("Dispositivos finalesssssssssssssssssss")
        ////console.log(dispositivosFinales2)
        DOCUMENTO.dispositivos = dispositivosFinales2
        DOCUMENTO.modulo = postRol
        DOCUMENTO.organizacion = valorOrganizacion
        DOCUMENTO.nombre = nombreAlerta
        Auth.saveAlert(DOCUMENTO)
        Swal.fire("Guardado exitosamente", "", "success");
        //arregloDispo
      }

      let url = `/admin/createCondition?organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`
      history.push(url);
    }

  }

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo> Crear Alerta Grupo</Titulo>

        </ContenedorTitulo>
        <ContenedorTabla>
          <NavbarTabla>
            <Input2
              message="Nombre Grupo"
              value={nombreAlerta}
              setFunction={setNombreAlerta}
              id="outlined-disabled"
            />
            {postUnico == null ? (
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "30ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Seleccione organizacion"
                  value={valorOrganizacion}
                  onChange={(e) => envioParametros(e.target.value)}
                >
                  {postData.map((option) => (
                    <MenuItem
                      key={option.organizacion}
                      value={option.organizacion}
                    >
                      {option.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            ) : null}

            {postControl ? (
              <>
                {" "}
                <Select
                  message="Dispositivos"
                  value={postRol}
                  setFunction={setRol}
                  data={valorObjetvo}
                  id="outlined-disabled"
                />
              </>
            ) : null}
          </NavbarTabla>

          <TablaDispo>
            {data != "" ? (
              <>
                <Tr>
                  <Th>Nombre</Th>
                  <Th>Dispositivo</Th>
                  <Th>Seleccionar<Checkbox onChange={(e) => seleccionarTodos(e)} /></Th>
                </Tr>

                {data.map((idnex) => (
                  <TrC>
                    <Th>{idnex.nombre}</Th>
                    <Th>{idnex.dispositivo}</Th>
                    <Th>
                      {selTodos ? <img src={Check} alt="MDN" style={{ "height": "40px" }} /> : <Checkbox onChange={(e) => addDevices(e, idnex.dispositivo)} />}

                    </Th>
                  </TrC>
                ))}
              </>
            ) : null}
          </TablaDispo>
        </ContenedorTabla>
        <Button url={`/admin/events?organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`} message="Atras" style={BotonRetroceder} />
        <BotonSiguiente onClick={() => sendData()}>Siguiente</BotonSiguiente>

      </Main>
    </>
  );
};
export default Index;
