import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import DateTimePicker from "@mui/lab/DateTimePicker";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import API from "../../../services/api-service";
import { Main, ContenedorTitulo, Titulo, ContenedorFechas, ContenedorF, TituloDispositivo, TituloID, ContenedorDatos, ContenedorI } from "../../Historico/ElementosStyles"
import Termometro from "../../Historico/Widgets/Temperatura/TemperaturaM"
import Humedad from "../../Historico/Widgets/MedidorHumedad/humedad"
import LineChart from "../../../../src/components/Dashboard/Charts/LineChart3"
import { TablaDispo, Tr, Th, TrC, DescripcionDispo } from "../../../views/Usuario Cuentas/UsuariosElements"
let respuesta = {
	ultimosV: {
		humidity: 8.59,
		temperature: 19.52,
		ultimoDato: "2024-03-19T11:12:00.000Z"
	},

	infoTemp: {
		warning: [
			{
				device: 'BC57290845DA',
				nombre: 'Climatizado 02_01',
				ultimoDato: '2024/03/14'
			}, {
				device: 'BC57290845DA',
				nombre: 'Climatizado 02_01',
				ultimoDato: '2024/03/14'
			}
		],
		device: ["BC57290845DA", "BC57290845D4"],
		nombre: ["Climatizado 02_01", "Climatizado 02_02"],
		typeDevice: ["s1_favorita_1", "s1_favorita_1"],
		tituloWidget: "Temperatura",
		variable: ["temperature", "temperature"],
		data: [
			{
				x: ['2024-03-11 15:03:00', '2024-03-11 15:08:00', '2024-03-11 15:13:00', '2024-03-11 15:18:00', '2024-03-11 15:23:00', '2024-03-11 15:28:00'],
				y: [8.76, 8.82, 8.81, 8.8, 8.78, 8.83]
			},
			{
				x: ['2024-03-11 15:03:00', '2024-03-11 15:08:00', '2024-03-11 15:13:00', '2024-03-11 15:18:00', '2024-03-11 15:23:00', '2024-03-11 15:28:00'],
				y: [1.76, 2.82, 2.81, 1.8, 2.78, 3.83]
			}
		]
	},

	infoHume: {
		device: ["BC57290845DA", "BC57290845D4"],
		nombre: ["Climatizado 02_01", "Climatizado 02_02"],
		typeDevice: ["s1_favorita_1", "s1_favorita_1"],
		tituloWidget: "Humedad",
		variable: ["humedad", "humedad"],
		data: [
			{
				x: ['2024-03-11 15:03:00', '2024-03-11 15:08:00', '2024-03-11 15:13:00', '2024-03-11 15:18:00', '2024-03-11 15:23:00', '2024-03-11 15:28:00'],
				y: [8.76, 2.82, 8.81, 3.8, 5.78, 1.83]
			},
			{
				x: ['2024-03-11 15:03:00', '2024-03-11 15:08:00', '2024-03-11 15:13:00', '2024-03-11 15:18:00', '2024-03-11 15:23:00', '2024-03-11 15:28:00'],
				y: [2.38, 8.39, 4.39, 8.39, 3.4, 8.43]
			}
		]
	}
}


const Index = () => {
	const [controladorC, setControladorC] = useState(false)
	const query = new URLSearchParams(useLocation().search);
	const organizacionn = query.get("organizacion");
	const localizacionn = query.get("localizacion");
	const id = query.get("id")
	const [fechaInicial, setFechaInicial] = useState(new Date().getTime() - 1000 * 60 * 60 * 24)
	const [intervalo, setIntervalo] = useState(10)
	const [fechaFinal, setFechaFinal] = useState(new Date().getTime())
	const area = query.get("area")
	const [respuestaA, setRespuestaA] = useState()
	const history = useHistory();

	useEffect(async () => {
		let Documento = {
			id: id
		}
		try {
			const response = await API.detalleArea(Documento);
			console.log(response)
			setRespuestaA(response);
			toast.success("Datos cargados!");
		} catch (error) {
			toast.error("Error al cargar datos!");

		}
	}, [controladorC]);

	const transformarFechaInicial = (value) => {
		setFechaInicial(value.getTime());
	};

	const transformarFechaFinal = (value) => {
		setFechaFinal(value.getTime());
	};

	const FechaUltima = () => {
		setControladorC(!controladorC)
	}

	const atras = () => {
        history.push(`/admin/ubicacionSensores?organizacion=${organizacionn}&localizacion=${localizacionn}`);
    };

	const parseFecha = () => {
		const fechaHoraSeparada = respuestaA[0].ultimosV.ultimoDato.split('T');
		const fecha = fechaHoraSeparada[0];
		const hora = fechaHoraSeparada[1].slice(0, 8);
		const fechaSeparada = fecha.split('-');
		const anio = fechaSeparada[0];
		const mes = fechaSeparada[1];
		const dia = fechaSeparada[2];
		const fechaFormateada = `${dia}/${mes}/${anio} ${hora}`;

		return fechaFormateada
	}

	return (
		<>
			<Main>
				<ContenedorFechas>
					<Button onClick={(e) => atras()}>Atras</Button>
				</ContenedorFechas>
				<ContenedorTitulo>Area {area}</ContenedorTitulo>
				<ContenedorFechas>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							renderInput={(props) => <TextField {...props} />}
							label="Fecha Inicial"
							value={fechaInicial}
							onChange={(e) => transformarFechaInicial(e)}
						/>
					</LocalizationProvider>
				</ContenedorFechas>
				<ContenedorFechas>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							renderInput={(props) => <TextField {...props} />}
							label="Fecha Final"
							value={fechaFinal}
							onChange={(e) => transformarFechaFinal(e)}
						/>
					</LocalizationProvider>
				</ContenedorFechas>
				<TextField
					label="Intervalo en minutos"
					value={intervalo}
					onChange={(e) => setIntervalo(e.target.value)}
					sx={{ width: 'fit-content' }}
				/>
				<ContenedorFechas>
					<Button className="buttonGeneral" onClick={FechaUltima} style={{
						margin: "0px 15px"
					}}>
						Buscar en Rango
					</Button>
				</ContenedorFechas>
				{respuestaA ?
					<>
						{respuesta.infoTemp.hasOwnProperty('warning') && (
							<>
								<div>Advertencia los siguientes dispostivios NO HAN REPORTADO</div>
								<TablaDispo>
									<Tr>
										<Th>Dispositivo</Th>
										<Th>Último Dato</Th>
									</Tr>
									{respuesta.infoTemp.warning.map((item) => {
										return (
											<TrC>
												<td>
													<DescripcionDispo>{item.nombre}</DescripcionDispo>
												</td>
												<td>
													<DescripcionDispo>{item.ultimoDato}</DescripcionDispo>
												</td>
											</TrC>

										)
									})}
								</TablaDispo>
							</>

						)}
						<div style={{ display: 'flex', marginTop: '30px' }}>
							<div style={{ flex: 0.5 }}>
								Temperatura promedio en {area}
								<p>{parseFecha()}</p>
								<Termometro info={respuestaA[0].ultimosV} />

							</div>
							<div style={{ flex: 1 }}>
								Humedad promedio en {area}
								<p>{parseFecha()}</p>
								<Humedad seriesData={respuestaA[0].ultimosV} />
							</div>
						</div>
						<LineChart info={respuesta.infoTemp} />
						<LineChart info={respuesta.infoHume} />
					</>

					: null}

			</Main>
		</>
	)
}

export default Index;