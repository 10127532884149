import React from "react";
import moment from "moment";
import { Line } from 'react-chartjs-2';
import { Resizable } from 'react-resizable';
import 'chartjs-adapter-moment';
import { Contenedor, Main } from '../LineChart/ElementsDisplay';

export default function App({ info, variables }) {
    console.log("Esto llega al line chart multiple");
    console.log(info[0]);

    let options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    tooltipFormat: 'll HH:mm'
                }
            }
        },
        maintainAspectRatio: false
    };

    const adjustedEjex = info.map((fecha) => {
        const date = new Date(fecha.fechaCompleta);
        date.setHours(date.getHours() + 5);
        return date;
    });

    const colors = [
        { backgroundColor: "#88DAEB", borderColor: "#88DAEB" },
        { backgroundColor: "#E4A2EF", borderColor: "#E4A2EF" },
        { backgroundColor: "#7680AE", borderColor: "#7680AE" }
    ];

    const datasets = variables.map((variable, index) => {
        const color = colors[index % colors.length];
        return {
            label: variable,
            data: info.map(item => item[variable]),
            fill: false,
            pointRadius: 1.8,
            backgroundColor: color.backgroundColor,
            borderColor: color.borderColor
        };
    });

    const data = {
        labels: adjustedEjex,
        datasets: datasets
    };

    options.plugins = {
        tooltip: {
            callbacks: {
                label: (context) => {
                    const index = context.dataIndex;
                    const datasetLabel = context.dataset.label;
                    const value = context.raw;
                    return `${datasetLabel}: ${value}`;
                }
            }
        }
    };

    return (
        <>
            {info.length > 0 ? (
                <Main>
                    <Contenedor>
                        <Resizable>
                            <Line data={data} options={options} />
                        </Resizable>
                    </Contenedor>
                </Main>
            ) : (
                <p>No hay Datos</p>
            )}
        </>
    );
}
