import React, { useEffect, useState } from "react";
import {
	ContenedorTitulo,
	Main,
	Titulo,
	ContenedorFecha
} from "../TestMapa/AlcantarillaElements";
import Api from "../../services/api-service"
import Tabla from "../Historico/Tabla/index"
import { useLocation } from "react-router-dom";

const Index = () => {
	const itemsPerPageOptions = [5, 10, 20, 50, 100, 200];
	const query = new URLSearchParams(useLocation().search);
	const obteOrganizacion = query.get("organizacion");
	const localizacion = query.get("localizacion");
	const [dispositivos, setDispositivos] = useState([])
	const [columnValues, setColumValues] = useState([])


	useEffect(async () => {
		try {
			console.log(obteOrganizacion, localizacion)
			if (obteOrganizacion === "uArtes") {
				Api.testingG(obteOrganizacion, localizacion).then(
					(respuesta) => {
						console.log(respuesta)
						setDispositivos(respuesta)
						setColumValues([
							{ nColumna: 'Nombre Dispositivo', nValor: 'nombre' },
							{ nColumna: 'ID Dispositivo', nValor: 'deviceID' },
							{ nColumna: 'Acciones', nValor: 'acciones' }
						])
					}
				)
			} else if (obteOrganizacion === "gpf" && localizacion === "gpf_loca_1") {
				Api.obtenerDispositivosHumitemp({
					organizacion: obteOrganizacion,
					estado: "all",
				}).then(
					(respuesta) => {
						console.log(respuesta)
						setDispositivos(respuesta)
						setColumValues([
							{ nColumna: 'ID Dispositivo', nValor: 'deviceID' },
							{ nColumna: 'Organización', nValor: 'organizacion' },
							{ nColumna: 'Nombre', nValor: 'nombre' },
							{ nColumna: 'Grupo', nValor: 'grupo' }
						])
					}
				)
			} else if (obteOrganizacion === "gpf" && localizacion === "gpf_loca_2") {
				setDispositivos([])
				Api.obtenerDispositivosS1({
					organizacion: obteOrganizacion,
					tipo: "ble_1",
				}).then(
					(respuesta) => {
						console.log(respuesta)
						setDispositivos(respuesta)
						setColumValues([
							{ nColumna: 'ID Dispositivo', nValor: 'deviceID' },
							{ nColumna: 'Organización', nValor: 'organizacion' },
							{ nColumna: 'Nombre', nValor: 'nombre' },
							{ nColumna: 'Grupo', nValor: 'grupo' }
						])
					}
				)
			}
		} catch (error) {
			console.log(error, 'error');
		}
	}, [obteOrganizacion, localizacion]);
	return (
		<>
			<Main>
				<ContenedorTitulo>
					<Titulo>Lista de dispositivos</Titulo>
				</ContenedorTitulo>


				{dispositivos.length > 0 ? <Tabla data={dispositivos} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnValues} /> : null}
			</Main >
		</>
	)
}

export default Index