import styled from "styled-components";

export const Main = styled.div`
  margin: 25px 30px 25px 30px;
  height: 100%;
  ${"" /* background: red; */}
`;
export const ContenedorTitulo = styled.h2`
  text-align: center;
  padding: 10px 15px;
  height: 45px;
`;
export const Titulo = styled.div``;

// Estilos de Creaciòn de Condicionales
export const ContPa1 = styled.div`
  // border: 2px solid red;
`;
export const ContPa2 = styled.div`
  margin: 10px;
  // border: 2px solid blue;
  display: inline-block;
`;
export const ContText = styled.div`
  
  display: inline-block;

  // border: 2px solid blue;
`;
export const ContPa3 = styled.div``;
export const ContPa4 = styled.div``;

export const Categorias = styled.div`
  display: inline-block;
  margin-left: 25px;
`;
export const ContenedorTabla = styled.div``;
export const NavbarTabla = styled.div`
  ${"" /* width: 100%; */}
  margin: 30px 15px;
  ${"" /* background: #254d8f; */}
  ${"" /* text-align: center; */}
`;
export const BotonFiltro = styled.div`
  display: inline-block;
  ${"" /* display: inline-block;
height: 40px;
width: 95.80007934570312px;
left: 20.373291015625px;
top: 117px;
border-radius: 6px;
background-color: transparent;
border:none; */
  }
`;
export const BarraBusqueda = styled.div`
  display: inline-block;
  margin-left: 42px;
  margin-right: 320px;
  height: 50px;
  width: 392px;
  background: #efefef;
  ${"" /* top: 50%; */}
  padding: 1% 0;

  ${"" /* left: 0px;
top: 0px;
border-radius: 6px;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;
box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
border-radius: 6px; */
  }

  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

export const IconoLupa = styled.div`
  display: inline-block;
  margin: 0 10px;
`;
export const InputBusqueda = styled.input`
  display: inline-block;
  width: 320px;
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  ${"" /* border-radius: 1px; */}
`;
export const BotonMostrar = styled.button`
  display: inline-block;
  float: left;
  margin: 10px;

  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  width: 210px;
  ${"" /* align-content: center; */}
  text-align: center;
  border: none;
  ${"" /* align-items: center; */}

  ${"" /* display: inline-block;
background: #114396;
color: white;
border-radius: 5px;
height: 49.999996185302734px;
width: 210px;
border:none; */
  }




${"" /* left: -0.1253662109375px;
box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
border-radius: 5px;
left: -0.06%;
right: 0.06%;
bottom: -0.39%;
box-shadow: 6px 0px 18px 0px #0000000F; */
  }
`;

export const BotonCrear = styled.button`
  display: inline-block;
  float: right;
  margin: 10px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  border: none;
  width: 210px;
  text-align: center;
`;
export const BotonSiguiente = styled.button`
  display: inline-block;
  float: right;
  margin: 25px 200px 50px 0;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  border: none;
  width: 210px;
  text-align: center;
`;
export const BotonRetroceder = styled.button`
  display: inline-block;
  float: left;
  margin: 25px 0 50px 200px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  border: none;
  width: 210px;
  text-align: center;
`;

export const Tr = styled.tr`
  ${"" /* position: absolute; */}
  left: 0.03%;
  right: 0.06%;
  top: 19.77%;
  bottom: 75.08%;
  background: #efefef;
  height: 45px;
`;

export const Th = styled.th`
  left: 25.01%;
  right: 61.36%;
  top: 21.49%;
  bottom: 76.46%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #4f4f4f;
  text-align: center;
  padding: 15px;
`;

export const TablaDispo = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TrC = styled.tr`
  text-align: center;
  ${"" /* margin: 15px 0 15px 0; */}
  ${"" /* border: solid; */}
border-width: 1px 0;
  border-color: #dadada;
  ${"" /* height: 30px; */}
  ${"" /* background: red; */}
`;

export const CoFiltroIn = styled.div`
  height: 45px;
  width: 100%;
  background: #efefef;
  text-align: right;
`;

export const Divfiltro = styled.div`
  display: inline-block;
  /* Filas por página : 10 */
  margin: 10px 30px 0 30px;

  /* Table/Footer */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.05em;

  /* text */
`;

export const Stiemererpo = styled.td``;

export const SelectFiltro = styled.select`
  border: none;
  background: #efefef;
`;
// dsdsd

export const ContenedorDis = styled.div``;
export const Nombredispositivo = styled.div`
  color: #114396;
`;
export const DescripcionDispo = styled.div`
  color: #4f4f4f;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  ${"" /* text-align: left; */}
`;
export const Organizacion = styled.div`
  color: #114396;
`;
export const Organizacion2 = styled.div`
  color: black;
`;
export const OrganizacionM = styled.div`
text-align:left;
  color: black;
`;
export const Tipo = styled.div`
  color: #4f4f4f;
`;
export const ContenedorFecha = styled.div``;
export const Estado = styled.div`
  color: #114396;
`;
export const Fecha = styled.div`
  color: #4f4f4f;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  ${"" /* text-align: left; */}
`;
export const Tiempo = styled.div`
  color: #114396;
`;
export const Puntos = styled.div`
  color: #4f4f4f;
`;

// nombre
// organizacion
// cantidadUsuarios
// estado
export const BotonDatos = styled.button`
  display: inline-block;
  ${"" /* float: right; */}
  margin: 10px;
  background: #0d6efd2e;
  color: black;
  border-radius: 5px;
  height: 42px;
  width: 110px;
  text-align: center;
  border: none;
`;

export const BotonAzul = styled.button`
  display: inline-block;
  ${"" /* float: right; */}
  margin: 10px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 42px;
  width: 110px;
  text-align: center;
  border: none;
`;

export const ParaBoton = styled.div`
  padding: 15px 15px;
  ${"" /* background: red; */}
`;
export const ParaMapa = styled.div`
  text-align: center;
  ${"" /* background: blue; */}
  height: 50px;
  ${"" /* padding: 50px 15px; */}
`;

export const BotonDevice = styled.button`
  display: inline-block;
  margin: 10px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  border: none;
  width: 210px;

  text-align: center;
`;

export const ContenedorButton = styled.div`
  padding: 25px 0;
  text-align: center;
`;

export const ContenedorCondicionales = styled.div`
  ${"" /* background:red */}
`;
export const ContenedorAcciones = styled.div`
  ${"" /* display: inline-block; */}
  height:50px;
  background: blue;
`;
export const DivisorU = styled.div`
  display: inline-block;
  width: 48%;
  height: 100%;
  background: green;
`;
export const DivisorS = styled.div`
  display: inline-block;
  width: 48%;
  height: 100%;
  background: black;
`;

export const DivisorP = styled.div`
  display: inline-block;
  width: 4%;
  height: 100%;
  background: red;

  ${"" /* border-top: 10px solid transparent;
border-bottom: 10px solid transparent;
border-left: 10px solid red; */
  }
`;
export const ContenedorAciones = styled.div`
text-align: center;
${'' /* border: black 5px solid; */}

`;
export const Aciones = styled.div`
 width: 250px;
margin: 0 90px 0 90px;
padding: 0 15px 0 15px;
display: inline-block;
${'' /* border: black 5px solid; */}
border-bottom: #7692D2 2px solid;
`;
export const AcionesD = styled.div`
 width: 250px;
margin: 0 90px 0 90px;
padding: 0 15px 0 15px;
display: inline-block;
`;
export const ContenedorM = styled.div`
padding: 15px 15px 15px 15px;
${'' /* border: black 1.5px solid; */}
`;
export const ContenedorMensajes = styled.div`
${'' /* border: black 1.5px solid; */}
`;
export const TituloMensaje = styled.div`
${'' /* border: black 1.5px solid; */}
`;
export const Mensajes = styled.textarea`
margin: 15px 0 0 0;
padding: 0 0 50px 0;
border: black 1.5px solid;
width:100%;
`;
export const ContenedorRaccion = styled.div`
margin: 15px 200px 0 0;
padding: 15px 50px 15px 50px;
${'' /* border: black 1.5px solid; */}
`;
export const BotonAccion = styled.div`
${'' /* border: black 1.5px solid; */}
display:inline;
`;
export const TituloAccion = styled.div`
${'' /* border: black 1.5px solid; */}
display:inline;
`;
export const ContendorRepeticion = styled.div`
${'' /* border: black 1.5px solid; */}

`;
export const EntradaHoraP = styled.input`
text-align:center;
  font-size: 16px;
  font-family: 'Verdana', sans-serif;
  font-weight: 400;
  line-height: 1.3;
  width:50px;
  box-sizing: border-box;
  margin: 0 5px 0 5px

`;
export const SelectHoraP = styled.select`
${'' /* display: block; */}
  font-size: 16px;
  font-family: 'Verdana', sans-serif;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  width:110px;
  ${'' /* padding: .4em 1.4em .3em .8em; */}
  ${'' /* width: 400px; */}
  ${'' /* max-width: 100%;  */}
  box-sizing: border-box;
  margin: 0 5px 0 5px
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
  border-radius: .3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
${'' /* width:50px;  */}
${'' /* margin: 0 5px 0 5px;
border: black 1.5px solid; */}
`;
export const ContenedorComuW = styled.div`
${'' /* border: black 1.5px solid; */}
width:500px;
height:50px;
${'' /* text-align:center;
  font-size: 16px;
  font-family: 'Verdana', sans-serif;
  font-weight: 400;
  line-height: 1.3;
  width:50px;
  box-sizing: border-box;
  margin: 0 5px 0 5px */}

`;

export const Icono_ContenedorComuW = styled.img`
${'' /* border: black 1.5px solid; */}
display: inline-block;
width:40px;
height:40px;
${'' /* text-align:center;
  font-size: 16px;
  font-family: 'Verdana', sans-serif;
  font-weight: 400;
  line-height: 1.3;
  width:50px;
  box-sizing: border-box;
  margin: 0 5px 0 5px */}

`;

export const Numero_ContenedorComuW = styled.div`
${'' /* border: black 1.5px solid; */}
display: inline-block;
padding: 0 25px 0 25px;
font-family: Verdana;
${'' /* border: black 1.5px solid; */}
${'' /* text-align:center;
  font-size: 16px;
  font-family: 'Verdana', sans-serif;
  font-weight: 400;
  line-height: 1.3;
  width:50px;
  box-sizing: border-box;
  margin: 0 5px 0 5px */}

`;

export const Icono2_ContenedorComuW = styled.button`
${'' /* border: black 1.5px solid; */}
float: right;
border:none;
width:28px;
${'' /* margin-right: 10px; */}
display: inline-block;
background-color: transparent !important;
${'' /* padding: 0 25px 0 25px */}
${'' /* text-align:center;
  font-size: 16px;
  font-family: 'Verdana', sans-serif;
  font-weight: 400;
  line-height: 1.3;
  width:50px;
  box-sizing: border-box;
  margin: 0 5px 0 5px */}

`;
export const Main2 = styled.div`
width: 100%;
  border-collapse: collapse;

`;

export const Botton2_Contenedor = styled.div`
margin: 15px 0px 15px 0px;


`;

export const Botton2 = styled.button`
width: 80px;
border-radius: 4px;
height:30px;
background:#0d6efd;
border:none;
color: white;
`;


export const Testing = styled.div`
padding: 50px 0 0 0;
`;
export const Verificacion = styled.p`
color:red
`;


