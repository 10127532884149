import React, { useState, useEffect } from "react";
import DateTimePicker from "@mui/lab/DateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import API from "../../services/api-service";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, Table, Card, Row, Col, Modal } from "react-bootstrap";
import {
    Main,
    ContenedorTitulo,
    Titulo,
    ContenedorFechas,
    ContenedorF,
    TituloDispositivo,
    TituloID,
    ContenedorDatos,
    ContenedorI,
    Tarjeta,
  } from "./ElementosStyles";

const ViewReports = () => {
  const query = new URLSearchParams(useLocation().search);
  const [controladorC, setControladorC] = useState(false);
  const [fechaInicial, setFechaInicial] = useState(
    new Date().getTime() - 1000 * 60 * 60 * 24
  );
  const history = useHistory();
  const [fechaFinal, setFechaFinal] = useState(new Date().getTime());
  const [activaciones, setActivaciones] = useState([]);

  const cambiarFechaI = (fecha) => {
    setFechaInicial(fecha);
  };

  const cambiarFechaF = (fecha) => {
    setFechaFinal(fecha);
  };

  const FechaUltima = () => {
    setControladorC(!controladorC);
  };
  const atras = () => {
    history.goBack();
  };

  useEffect(() => {
    const fetchActivaciones = async () => {
      try {
        const organizacionn = query.get("organizacion");
        const localizacionn = query.get("localizacion");

        let Documento = {
          fechaInicial: fechaInicial,
          fechaFinal: fechaFinal,
          organizacion: organizacionn,
          localizacion: localizacionn
        };
        const Activaciones = await API.obtenerActivaciones(Documento)
        console.log("Llego la data")
        console.log(Activaciones)
        setActivaciones(Activaciones);
      } catch (error) {
        console.error("Error fetching activaciones:", error);
      }
    };

    fetchActivaciones();
  }, [controladorC]);

  const formatFecha = (fechaCompleta) => {
    const date = new Date(fechaCompleta);
    date.setHours(date.getHours() + 5); // Sumar 5 horas
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
  };

  return (
    <Main>
      <ContenedorFechas>
        <Button onClick={atras}>Atras</Button>
      </ContenedorFechas>
      <ContenedorTitulo>Activaciones de Sirenas</ContenedorTitulo>

      <ContenedorFechas>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Fecha Inicial"
            value={fechaInicial}
            onChange={cambiarFechaI}
          />
        </LocalizationProvider>
      </ContenedorFechas>
      <ContenedorFechas>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Fecha Final"
            value={fechaFinal}
            onChange={cambiarFechaF}
          />
        </LocalizationProvider>
      </ContenedorFechas>
      <ContenedorFechas>
        <Button
          className="buttonGeneral"
          onClick={FechaUltima}
          style={{
            margin: "0px 15px",
          }}
        >
          Buscar en Rango
        </Button>
      </ContenedorFechas>

      {activaciones.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Sirena</th>
              <th>Modo de Activación</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {activaciones.map((activacion) => (
              <tr key={activacion._id}>
                <td>{activacion.deviceID}</td>
                <td>{activacion.modo === "MQTT" ? "App" : "Control Remoto RF"}</td>
                <td>{formatFecha(activacion.fechaCompleta)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Main>
  );
}

export default ViewReports;
