/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";
import NotificationDropdown from './NotificationDropdown.js'
import UserDropdown from './UserDropdown.js'

import { Nav } from "react-bootstrap";

import "./Top.css";
import DateAndHour from "./DateAndHour.js";


function Top({ color, image, routes }) {


  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="top" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <a
            href="https://constecoin.com"
            className="simple-text logo-mini mx-1"
          >
            <div>
              <img className = "logo-img"
                src={require("../../assets/img/logo_constecoin.png").default} 
                alt="..."
              />
            </div>
          </a>
          {/* <a className="simple-text" href="http://www.creative-tim.com">
            CONSTECOIN
          </a> */}
          <div className="notification-top">
            <DateAndHour/>
            <NotificationDropdown/>
            <UserDropdown/>
          </div>

        </div>
        {/* <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li
                  // className={
                  //   prop.upgrade
                  //     ? "active active-pro"
                  //     : activeRoute(prop.layout + prop.path)
                  // }
                  // key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            return null;
          })}
        </Nav> */}


      </div>
    </div>
  );
}

// max-width: 200px;



export default Top;
