import React, { useState, useEffect } from "react";
import { Main, ContenedorTitulo, ContenedorButton, BotonDevice } from "../ElementosStyles";
import { useHistory } from "react-router-dom";
import API from "../../../services/api-service"
import { Button } from 'react-bootstrap';
import Input from "../../../components/Entradas/Input/index"
import ButtonS from "../../../components/Button/index"
import Swal from "sweetalert2";

const CrearProducto = () => {
    const [nombrePro, setnombrePro] = useState()
    const [codProducto, setCodProducto] = useState()
    const [descripcion, setDescripcion] = useState()
    const [productos, setProductos] = useState()
    var history = useHistory();

    function crearProducto (){
      let documento
      const existeProducto = productos.find(producto => producto.codigo == codProducto)

      if(existeProducto){
        Swal.fire("ERROR", `Ya existe un producto con el código ${codProducto}`, "error")
      }else{
        if (descripcion != ""){
          documento = {
            codigo: codProducto,
            nombre: nombrePro,
            Descripcion: descripcion,
          }
          {console.log(documento)}
        }else{
          documento = {
            codigo: codProducto,
            nombre: nombrePro,
          }
        }
        API.crearProductoFav(documento).then((respuesta) => {
          console.log(respuesta)
          if (respuesta.success == true) {
            console.log("Entro aqui")
            Swal.fire(`${respuesta.message}`, "", "success");
            var url = `/admin/verProductos`
            history.push(url);
          } else {
            Swal.fire(`${respuesta.message}`, "", "error");
          }
        })
      }
     
    }

    useEffect(() =>{
      API.obtenerProductosFav().then((result) => {
        setProductos(result)
      });
    }, [])
    return (
        <>

            <Main>
                <ContenedorTitulo>
                    Crear nuevo producto
                </ContenedorTitulo>
                <Input
                    message="Ingrese el código del producto"
                    value={codProducto}
                    setFunction={setCodProducto}
                    id="outlined-disabled"
                />
                <Input
                    message="Ingrese el nombre del producto"
                    value={nombrePro}
                    setFunction={setnombrePro}
                    id="outlined-disabled"
                />
                <Input
                    message="Ingrese la descripción del producto"
                    value={descripcion}
                    setFunction={setDescripcion}
                    id="outlined-disabled"
                />

                <ContenedorButton>
                    <ButtonS
                        url={`/admin/verProductos`}
                        message="Regresar"
                        style={BotonDevice}
                    >
                        Regresar
                    </ButtonS>
                    <BotonDevice
                        onClick={() => {
                          crearProducto()
                        }}
                    >
                        Guardar
                    </BotonDevice>
                </ContenedorButton>
            </Main>
        </>


    )


}

export default CrearProducto