import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Line } from "react-chartjs-2";
import toast, { Toaster } from "react-hot-toast";
import "chart.js/auto";
import Temperatura from "../Historico/Widgets/Temperatura/TemperaturaM";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
import AUTH from "../../services/auth-service";
import API from "../../services/api-service";
import {
  ContenedorTitulo,
  Main,
  ParaBoton,
  Title,
  Tarjeta,
  IconContainer,
  MegaphoneIcon,
} from "./AlcantarillaElements";
import {
  ContenedorFechas,
  TituloDispositivo,
} from "../Historico/ElementosStyles";
import Humedad from "../Historico/Widgets/MedidorHumedad/humedad";
import { FaDoorClosed, FaDoorOpen } from "react-icons/fa";
import { IoIosFlash, IoIosFlashOff } from "react-icons/io";
import Tooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import { FaVolumeUp, FaVolumeOff } from "react-icons/fa"; // Importar iconos de sirena
import { BsLightbulbFill, BsLightbulbOffFill } from "react-icons/bs"; // Importar iconos de bombillo

//este es un comentario de prueba para verificar el usuario de git

const Dashboard = () => {
  const [tiempo, setTiempo] = useState("");
  const [historialDatos, setHistorialDatos] = useState([]);
  const [idSocket, setIdSocket] = useState(null)
  const [fechaInicial, setFechaInicial] = useState(
    new Date().getTime() - 1000 * 60 * 60 * 24
  );
  const [fechaFinal, setFechaFinal] = useState(new Date().getTime());
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  var ID
  const obteOrganizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const [rol, setRol] = useState();
  // Estado para almacenar los datos del monitoreo
  const [data, setData] = useState(null);
  const [bloqueoRF, setBloqueoRF] = useState(null);
  const [bloqueoGe, setBloqueoGe] = useState(null);
  const history = useHistory();
  const [activacion, setActivacion] = useState({
    modo: "",
    fecha: "",
  });
  const socketRef = useRef(null);
  const [advertencia, setAdvertencia] = useState("");
  const [bombilloEncendido, setBombilloEncendido] = useState(false); // Estado para el bombillo
  const [sirenaActiva, setSirenaActiva] = useState(false);
  const [timer, setTimer] = useState(null);

  const [checkingSirena, setCheckingSirena] = useState(false);
  const [sirenaStatusMessage, setSirenaStatusMessage] = useState("");

  useEffect(() => {
    consumirAPI();
  }, []);

  useEffect(() => {
    socketRef.current = io("https://aiot.constecoin.com");

    console.log("Conectado");

    if (localizacion == "sto_epconst_loca_2") {
      ID = `sto/${id}`;
    } else if (localizacion == "iba_gad_loca_1") {
      ID = `${id}`;
    } else {
      ID = id;
    }

    setIdSocket(ID)

    socketRef.current.on(`${ID}/out`, (msg) => {
      console.log(msg);
      if (msg && msg.monitoreo) {
        setHistorialDatos((historialPrevio) => [
          ...historialPrevio,
          {
            temperatura: msg.monitoreo.temp,
            humedad: msg.monitoreo.hum,
            ejeX: ajustarFecha(msg.fechaCompleta),
          },
        ]);
        setData({
          temperatura: msg.monitoreo.temp,
          humedad: msg.monitoreo.hum,
          energia: msg.monitoreo.stateAC,
          puerta: msg.monitoreo.stateDO,
          fecha: separarFecha(msg.fechaCompleta),
        });
      }
      if (msg && msg.tipo === "Activacion") {
        if (msg.estado === "false") {
          console.log("Aqui esta llegando las cosas 2")
          console.log(msg)
          setActivacion({
            modo: msg.modo,
            fecha: msg.fechaCompleta,
          });
        }
        if (msg.estado === "true") {
          console.log("Entro a setearsseeee")
          setSirenaActiva(true);

          // Configura un temporizador para desactivar la sirena después de 2 minutos
          if (timer) {
            clearTimeout(timer);
          }
          const newTimer = setTimeout(() => {
            setSirenaActiva(false);
          }, 2 * 60 * 1000); // 2 minutos en milisegundos
          setTimer(newTimer);
        } else {
          setSirenaActiva(false);
        }
      }

      if (msg && msg.modo === "BloqueoGeneral") {
        console.log("BLOQUEO GENERAL:", msg);
        if (msg.status) {
          setBloqueoGe({ "estado": msg.status, "fecha": msg.fecha });
        } else {
          setBloqueoGe({ "estado": msg.status });
        }
      }


      if (msg && msg.modo === "BloqueoRF") {
        console.log("Esta llegando para bloquear RF")
        if (msg.status) {
          setBloqueoRF({ "estado": msg.status, "fecha": msg.fecha });
        } else {
          setBloqueoRF({ "estado": msg.status });
        }
      }
      console.log(historialDatos);
    });

    setRol(AUTH.getRol());

    return () => {
      if (socketRef.current) {
        socketRef.current.off(`${ID}/out`);
        socketRef.current.disconnect();
      }
    };
  }, [id]);

  async function consumirAPI() {
    const auth = await AUTH.getOrganizaciones();
    console.log(auth);

    let catalogo = "";

    auth.forEach((index) => {
      index.localizacion.forEach((locali) => {
        if (locali.localiza === localizacion) {
          locali.configuraciones.forEach((conf) => {
            if (conf.tipo === "mapa") {
              catalogo = conf.catalogo;
            }
          });
        }
      });
    });

    const loadingToastId = toast.loading("Cargando...");

    try {
      const respuesta = await API.monitoreoDispositivos(ID, fechaInicial, fechaFinal, catalogo);
      console.log(respuesta);

      if (respuesta.message !== "error") {
        procesarRespuesta(respuesta);
        toast.success("Datos cargados");
      } else {
        const segundaRespuesta = await API.monitoreoDispositivosUD(ID, catalogo);
        console.log("entrando a la segunda API");
        console.log(segundaRespuesta);

        if (segundaRespuesta.message !== "error") {
          setAdvertencia("Este dispositivo no ha reportado desde el: ")
          procesarRespuesta(segundaRespuesta);
          toast.success("Datos cargados");
        } else {
          toast.error("No se encontraron datos");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Error al cargar los datos");
    } finally {
      toast.dismiss(loadingToastId);
    }
  }

  function procesarRespuesta(respuesta) {
    const tempArray = [];
    for (let i = respuesta.temperatura.length - 1; i >= 0; i--) {
      const newObj = {
        temperatura: respuesta.temperatura[i],
        humedad: respuesta.humedad[i],
        ejeX: ajustarFecha(respuesta.ejeX[i]),
      };
      tempArray.push(newObj);
    }
    setHistorialDatos(tempArray);
    setData({
      puerta: respuesta.door[0],
      energia: respuesta.energy[0],
      temperatura: respuesta.temperatura[0],
      humedad: respuesta.humedad[0],
      fecha: separarFecha(respuesta.ejeX[0])
    });
    if (respuesta.bloqueoGeneral !== undefined) {
      setBloqueoGe(respuesta.bloqueoGeneral);
    } else {
      setBloqueoGe(false);
    }
    if (respuesta.bloqueoRF !== undefined) {
      setBloqueoRF(respuesta.bloqueoRF);
    } else {
      setBloqueoRF(false);
    }
  }


  const ajustarFecha = (fecha) => {
    const fechaAjustada = new Date(fecha);
    fechaAjustada.setHours(fechaAjustada.getHours() + 5);
    return fechaAjustada;
  }

  const separarFecha = (fecha) => {
    console.log("entrando a la función de la fecha");
    console.log(fecha);
    var fechaFormateada = "";
    const fechaHoraSeparada = fecha.split("T");
    const date = fechaHoraSeparada[0];
    const hora = fechaHoraSeparada[1].slice(0, 8);
    const fechaSeparada = date.split("-");
    const anio = fechaSeparada[0];
    const mes = fechaSeparada[1];
    const dia = fechaSeparada[2];
    fechaFormateada = `${dia}/${mes}/${anio} ${hora}`;
    return fechaFormateada;
  }

  const datosGrafico = {
    labels: historialDatos.map((d) => d.ejeX.toLocaleTimeString()), // Convierte las fechas a strings
    datasets: [
      {
        label: "Temperatura (°C)",
        data: historialDatos.map((d) => d.temperatura),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Humedad (%)",
        data: historialDatos.map((d) => d.humedad),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const handleClick = () => {
    console.log("Imprimiendo aquí");
    if (socketRef.current) {
      console.log("Emitiendo evento 'hola' con valor:", "001");
      socketRef.current.emit("hola", idSocket);
    }
  };


  const cambiarbloqueoGeneral = (valor) => {
    let documento = {
      id: idSocket,
      valor: valor
    }
    console.log("Se esta oprimiendo el boton");
    if (socketRef.current) {
      console.log("Emitiendo evento 'hola' con valor:", "001");
      socketRef.current.emit("bloqueoGeneral", documento);
    }
  };

  const cambiarbloqueoRF = (valor) => {
    let documento = {
      id: idSocket,
      valor: valor
    }
    console.log("Imprimiendo aquí");
    if (socketRef.current) {
      console.log("Emitiendo evento 'hola' con valor:", "001");
      socketRef.current.emit("bloqueoRF", documento);
    }
  };

  const cambiarTiempo = () => {
    console.log("Imprimiendo aquí");
    if (socketRef.current) {
      console.log("Emitiendo evento 'hola' con valor:", "001");
      let Documento = {
        id: idSocket,
        tiempo:

          tiempo,
      };
      socketRef.current.emit("tiempo", Documento);
    }
  };

  const chequear = () => {
    if (socketRef.current) {
      setCheckingSirena(true);
      setSirenaStatusMessage("");

      console.log("esto esta llegando en el socket")
      console.log(idSocket)

      socketRef.current.emit("hola", idSocket);

      const timer = setTimeout(() => {
        setCheckingSirena(false);
        setSirenaStatusMessage("La sirena no está funcionando");
      }, 30000);

      socketRef.current.on(`${idSocket}/out`, (msg) => {
        if (msg && msg.tipo === "Activacion") {
          clearTimeout(timer);
          setCheckingSirena(false);
          if (msg.estado === "false") {
            setSirenaStatusMessage("La sirena está funcionando");
          }
        }

      });
    }
  }

  const atras = () => {
    history.goBack();
  };

  return (
    <Main>

      <ParaBoton>
        <Button onClick={(e) => atras()}>Atras</Button>
      </ParaBoton>
      <ContenedorTitulo>Dispositivo: {id}</ContenedorTitulo>
      {data != null ? (
        <>
          {advertencia != "" ? (
            <div style={{ color: "red" }}>{advertencia}{data.fecha}</div>
          ) : (null)}
          <Tarjeta>
            <TituloDispositivo>Monitoreo</TituloDispositivo>
            {console.log("esta es la fecha")}
            {console.log(data.fecha)}
            Fecha: {data.fecha}
            <div style={{ display: "flex" }}>
              <div style={{ flex: 0.3 }}>
                Temperatura
                <Temperatura info={data} />
              </div>
              <div style={{ flex: 0.3 }}>
                Humedad
                <Humedad seriesData={data} />
              </div>
              {data.puerta === true ? (
                <div style={{ flex: 0.3 }}>
                  Puerta abierta
                  <FaDoorOpen
                    style={{
                      width: "80%",
                      height: "100%",
                      color: "rgba(111, 234, 208)",
                    }}
                  />
                </div>
              ) : (
                <div style={{ flex: 0.3 }}>
                  Puerta cerrada
                  <FaDoorClosed
                    style={{
                      width: "80%",
                      height: "100%",
                      color: "rgba(111, 234, 208)",
                    }}
                  />
                </div>
              )}
              {data.energia === true ? (
                <div style={{ flex: 0.3 }}>
                  Sin energía
                  <IoIosFlashOff
                    style={{
                      width: "80%",
                      height: "100%",
                      color: "rgba(255, 208, 91)",
                    }}
                  />
                </div>
              ) : (
                <div style={{ flex: 0.3 }}>
                  Con energía
                  <IoIosFlash
                    style={{
                      width: "80%",
                      height: "100%",
                      color: "rgba(255, 208, 91)",
                    }}
                  />
                </div>
              )}
            </div>
          </Tarjeta>

          {localizacion === "sto_epconst_loca_1" ? null : (
            <Tarjeta>
              <TituloDispositivo>Activación</TituloDispositivo>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h5>Estado Sirena</h5>
                  <Tooltip id="mapa-tooltip" place="right" effect="solid">
                    Activar sirena
                  </Tooltip>
                  <MegaphoneIcon
                    data-tip
                    data-for="mapa-tooltip"
                    onClick={handleClick}
                  />
                  <Button onClick={chequear} style={{ marginTop: '10px' }}>
                    Chequear
                  </Button>
                  {checkingSirena && <p>Verificando...</p>}
                  {sirenaStatusMessage && <p>{sirenaStatusMessage}</p>}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h5>Actividad sirena</h5>
                  <div style={{ textAlign: 'center' }}>
                    {sirenaActiva ? (
                      <>
                        <p>Sirena sonando</p>
                        <div>Modo: {activacion.modo}</div>
                        <FaVolumeUp
                          style={{
                            width: "50%",
                            height: "50%",
                            color: "red",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <FaVolumeOff
                          style={{
                            width: "50%",
                            height: "50%",
                            color: "gray",
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h5>Configuración bloqueo/desbloqueo</h5>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <div style={{ textAlign: 'center' }}>
                      {bloqueoGe !== null ? (
                        bloqueoGe.estado ? (
                          <>
                            <div>Sirena Bloqueada</div>
                            <div>Fecha de bloqueo: {bloqueoGe.fecha}</div>
                            <Button
                              variant="success"
                              onClick={() => cambiarbloqueoGeneral(false)}
                              style={{ marginTop: '10px' }}
                            >
                              Desbloquear
                            </Button>
                          </>
                        ) : (
                          <>
                            <div>Sirena Funcional</div>
                            <Button
                              variant="danger"
                              onClick={() => cambiarbloqueoGeneral(true)}
                              style={{ marginTop: '10px' }}
                            >
                              Bloquear
                            </Button>
                          </>
                        )
                      ) : (
                        <div>No se ha recibido información de bloqueo general</div>
                      )}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      {bloqueoRF !== null ? (
                        bloqueoRF.estado ? (
                          <>
                            <div>RF Bloqueado</div>
                            <div>Fecha de bloqueo: {bloqueoRF.fecha}</div>
                            <Button
                              variant="success"
                              onClick={() => cambiarbloqueoRF(false)}
                              style={{ marginTop: '10px' }}
                            >
                              Desbloquear
                            </Button>
                          </>
                        ) : (
                          <>
                            <div>RF Funcional</div>
                            <Button
                              variant="danger"
                              onClick={() => cambiarbloqueoRF(true)}
                              style={{ marginTop: '10px' }}
                            >
                              Bloquear
                            </Button>
                          </>
                        )
                      ) : (
                        <div>No se ha recibido información de bloqueo general</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Tarjeta>
          )}

          <Tarjeta>
            {rol == "administrador" ? (
              <>
                {localizacion == "sto_epconst_loca_1" ? null : (
                  <>
                    {" "}
                    <TituloDispositivo>Cambiar Tiempo Monitoreo</TituloDispositivo>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p>
                        Tiempo (segundos):
                        <input
                          type="number"
                          value={tiempo}
                          onChange={(e) => setTiempo(e.target.value)}
                          style={{ marginLeft: "10px" }}
                        />
                      </p>
                      <ParaBoton>
                        <Button onClick={cambiarTiempo}>Enviar</Button>
                      </ParaBoton>
                    </div>
                  </>
                )}
              </>
            ) : null}
            <div style={{ height: "300px", width: "600px" }}>
              <Line data={datosGrafico} />
            </div>
          </Tarjeta>
        </>
      ) : (null)}
      <Toaster position="top-center" reverseOrder={false} />
    </Main>
  );
};

export default Dashboard;
