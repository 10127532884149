import React, { useState, useMemo, useEffect } from 'react'
import TextField from "@mui/material/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Informa from "../../Informacion/index";
import Checkbox from '@material-ui/core/Checkbox';
import Api from '../../../services/api-service.js';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { SketchPicker as Picker } from 'react-color';
import { ContenedorTitulo, Main, Opciones, BotonGuardar, ContenedorBotones, ContenedorBotoness } from '../Elements'
import Stack from "@mui/material/Stack";

const   LineTest = ({ data, valorWid, device, variable, variableElegida, DispositivoE, saveData, valorActual, funcionE, children }) => {

    const [tituloWidget, setTituloWidget] = useState(valorActual.widget.tituloWidget)
    const [vNombreDispotivo, setVNombreDispotivo] = useState(valorActual.widget.viwes.Mdevice)
    const [vIdDispositivo, setVIdDispositivo] = useState(valorActual.widget.viwes.Mnombre)
    const [tiempoReporte, setTiempoReporte] = useState(valorActual.widget.reporte)
    const [backgroundColor, setBackgroudColor] = useState(valorActual.widget.background)
    const [agregacion, setAgregacion] = useState(valorActual.widget.agregacion)
    const [tipo, setTipo] = useState(valorActual.widget.tipo)
    const [variables, setVariables] = useState(valorActual.widget.variable)
    const [devices, setDevices] = useState(valorActual.widget.device)


    const GuardarData = () => {   
        console.log(variableElegida)
        console.log(variable)
        console.log(device)
        var nombreVariable = [variables[0].nombre] 
        let Views = {
            "Mdevice": [false],
            "Mnombre": [false]
        }
        let maxY
        let cantidad
        let maxX
        if (Object.keys(data).length == 4) {
            maxY = 0
            cantidad = 1
            data.posiciones = []
            data.identificadores = []
        } else {
            maxY = data.posiciones[0].y;
            maxX = data.posiciones[0].x;
            cantidad = data.posiciones.length + 1;
            cantidad = cantidad.toString();

            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y > maxY) {
                    maxY = data.posiciones[i].y;
                }
            }
            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y == maxY) {
                    if (data.posiciones[i].x > maxX) {
                        maxX = data.posiciones[i].x;
                    }
                }
            }
        }

        if (maxX > 4) {
            maxX = 0
            maxY = maxY + 1
        } else {
            maxX = maxX + 1
        }

        let nombres = {
            name: valorWid,
            device: devices,
            variable: variables,
            typeDevice: DispositivoE,
            reporte: Number(tiempoReporte),
            background: hexColor,
            agregacion: agregacion,
            tipo: tipo,
            nombre: nombreVariable
        };


        if (tituloWidget != "") {
            nombres.tituloWidget = tituloWidget
        }
        if (vNombreDispotivo) {
            Views.Mnombre.push(true)
        }
        if (vIdDispositivo) {
            Views.Mdevice.push(true)
        }
        nombres.viwes = Views
        let newWidget = {
            w: 11,
            h: 2,
            x: maxX,
            y: maxY,
            i: cantidad,
            moved: false,
            static: false,
        };
        data.posiciones.push(newWidget);
        data.identificadores.push(nombres);
        saveData(data)
        console.log("Esta tiene data.identificadores")
        console.log(data.identificadores)
        console.log("Esto tiene nombres")
        console.log(nombres)
    };

    useEffect(() => {
        if (device && !devices.includes(device)) {
            setDevices([device, ...devices]);
        }
      }, [device, devices]);
    
      useEffect(() => {
        if (variable && !variables.includes(variable)) {
            setVariables([variable, ...variables]);
        }
      }, [variable, variables]);

    const VerificarTituloWidget = (e) => {
        const inputValue = e.target.value;
        setTituloWidget(inputValue)
    }

    const handleChange = (event) => {
        setVNombreDispotivo(event.target.checked);
    };
    const handleChangeD = (event) => {
        setVIdDispositivo(event.target.checked);
    };
    

    const enviarEliminar = () => {
        funcionE()
    }

    const submitForm = () => {
        if (data.identificadores[valorActual.index].tituloWidget != tituloWidget) {
            data.identificadores[valorActual.index].tituloWidget = tituloWidget
        }        
        if (data.identificadores[valorActual.index].viwes.Mnombre != vNombreDispotivo) {
            data.identificadores[valorActual.index].viwes.Mnombre = vNombreDispotivo
        }
        if (data.identificadores[valorActual.index].viwes.Mdevice != vIdDispositivo) {
            data.identificadores[valorActual.index].viwes.Mdevice = vIdDispositivo
        }        
        if (data.identificadores[valorActual.index].reporte != tiempoReporte) {
            data.identificadores[valorActual.index].reporte = tiempoReporte
        }
        if (data.identificadores[valorActual.index].background!= backgroundColor) {
            data.identificadores[valorActual.index].background = backgroundColor.hex
        }
        if (data.identificadores[valorActual.index].agregacion != agregacion){
            data.identificadores[valorActual.index].agregacion = agregacion
        }
        

        console.log("Esta es la data que se envia para actualizar")
        console.log(data)
        
        
        Api.actualizarWidget(data).then(
            result => {
                console.log(result)
                saveData()
            }, error => {
                alert(error)
                console.log(error.error)

            }
        )
    }

    const decimalToHex = (alpha) =>
        alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

    const hexColor = useMemo(() => {
        if (typeof backgroundColor === 'string') {
            return backgroundColor;
        }
        return `${backgroundColor?.hex}${decimalToHex(backgroundColor?.rgb?.a)}`;
    }, [backgroundColor]);

    return (
        <>
            <Main>
                <ContenedorTitulo>
                    Parámetros
                </ContenedorTitulo>
                <TextField
                    label="Titulo Widget"
                    value={tituloWidget}
                    onChange={VerificarTituloWidget}
                />
                <Opciones>
                    <FormControlLabel
                        control={
                            <Checkbox checked={vNombreDispotivo}
                                onChange={handleChange}
                                name="Nombre del Dispositivo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="Nombre del Dispositivo"
                    /><Informa
                        info="Si desea que el nombre del dispositivo aparezca en el widget, marque la casilla."
                    />
                </Opciones>
                <Opciones>

                    <FormControlLabel
                        control={
                            <Checkbox checked={vIdDispositivo}
                                onChange={handleChangeD}
                                name="ID Disposiivo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="ID Dispositivo"
                    />
                </Opciones>
                <Opciones>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Tiempo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={tiempoReporte}
                            label="Estado"
                            onChange={(e) => setTiempoReporte(e.target.value)}
                        >
                            <MenuItem value="1440">ultimas 24 horas</MenuItem>
                            <MenuItem value="10080">ultima semana</MenuItem>                            
                        </Select>
                    </FormControl>
                </Opciones>
                <Opciones>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Agregación</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={agregacion}
                            label="Estado"
                            onChange={(e) => setAgregacion(e.target.value)}
                        >

                            <MenuItem value="Max">Máximo</MenuItem>
                            <MenuItem value="Min">Mínimo</MenuItem>                            
                            <MenuItem value="Total">Suma</MenuItem>                            
                            <MenuItem value="Prom">Promedio</MenuItem>                            
                        </Select>
                    </FormControl>
                </Opciones>                
                <Opciones>                    
                    <Picker color={hexColor} onChange={setBackgroudColor} />
                </Opciones>
                {children}
                <ContenedorBotones>
                    <ContenedorBotoness>
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" onClick={() => submitForm()}>
                                Actualizar
                            </Button>
                            <Button variant="outlined" color="error" onClick={enviarEliminar}>
                                Eliminar
                            </Button>
                        </Stack>
                    </ContenedorBotoness>
                </ContenedorBotones>
            </Main>
        </>
    )
}

export default LineTest
