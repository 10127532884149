import React from "react";
import "./style.scss"
import { Actalizacion, Contenedor, ContenedorWa, IconoEdit, ImagenWid, IndicadorT, Main, NombreD, StyledReactGridItem, Testing, Titulo } from './ElementsDisplay';
import EditIcon from '@material-ui/icons/Edit';
import Advertencia from "./warning.png"
import { Resizable } from 'react-resizable';
import './humedad.css'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
class Thermometer extends React.Component {
  constructor(props) {
    super(props);    
    console.log("-------------------------------ACA---------------------");
    console.log(this.props.value);    
    this.eliminarWidget = this.props.funcionE.bind(this);
    
    this.state = {
      Mnombre: this.props.info.viwes.Mnombre || false,
      Mdevice: this.props.info.viwes.Mdevice || false,
      index: this.props.index,
      info: this.props.info,
      ultimoData: this.props.info.ultimoDato || false,
      nombreWidget: this.props.info.tituloWidget || false,
      nombeDiposositivo: this.props.info.nombre || false,
      idDispositivo: this.props.info.device || false,
      año: this.props.funcionF(this.props.info.ultimoDato),


      // background: this.props.info.background || "#ffffff",
      background: this.props.info.background,
      

      theme: () => this.props.theme === 'light' || this.props.theme === 'dark' ? this.props.theme : 'light',
      value: this.props.info.data || 0, //default 0
      max: this.props.info.puntoMaximo || 100, //default 100
      steps: this.props.steps || 4, //default 4
      format: this.props.format || '°C',
      size: () => this.props.size === 'small' || this.props.size === 'normal' || this.props.size === 'large' ? this.props.size : 'normal',
      height: this.props.height || 200, //default 200
      valstr: () => this.state.value + " " + this.state.format,
      percent: () => this.state.value / this.state.max * 100,
      intervals: []
    }

    for (let step = 0; step <= this.state.steps; step++) {
      let val = ((this.state.max / this.state.steps) * step).toFixed(2);
      let percent = (val / this.state.max) * 100;
      let interval = { percent: percent, label: val + this.state.format };
      this.state.intervals.push(interval);
    }
  }

  funcion = () => {
    this.eliminarWidget({ index: this.state.index, widget: this.state.info })
  }

 
  
  handleMouseOver = (event) => {
    event.target.style.cursor = 'pointer';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.actualizar !== this.props.actualizar) {
      this.setState({
        Mnombre: this.props.info.viwes.Mnombre || false,
        Mdevice: this.props.info.viwes.Mdevice || false,
        index: this.props.index,
        info: this.props.info,
        ultimoData: this.props.info.ultimoDato || false,
        nombreWidget: this.props.info.tituloWidget || false,
        nombeDiposositivo: this.props.info.nombre || false,
        idDispositivo: this.props.info.device || false,
        background: this.props.info.background || "#ffffff",

        theme: () => this.props.theme === 'light' || this.props.theme === 'dark' ? this.props.theme : 'light',
        value: this.props.info.data || 0, //default 0
        max: this.props.info.puntoMaximo || 100, //default 100
        steps: this.props.steps || 4, //default 4
        format: this.props.format || '°C',
        size: () => this.props.size === 'small' || this.props.size === 'normal' || this.props.size === 'large' ? this.props.size : 'normal',
        height: this.props.height || 200, //default 200
        valstr: () => this.state.value + " " + this.state.format,
        percent: () => this.state.value / this.state.max * 100,

      });
      for (let step = 0; step <= this.state.steps; step++) {
        let val = ((this.state.max / this.state.steps) * step).toFixed(2);
        let percent = (val / this.state.max) * 100;
        let interval = { percent: percent, label: val + this.state.format };
        this.state.intervals.push(interval);
      }
      console.log("Entro en el nuevoooooasasasooooooo")
    }
  }

  render() {
    const theme = `thermometer--theme-${this.state.theme()}`;
    const size = `thermometer--${this.state.size()}`;
    const height = { height: `${this.state.height}px` };
    const heightPercent = { height: `${this.state.percent()}%` };
    const heightBgColor = { height: `calc(${this.state.height}px - 57px)` };
    const valstr = this.state.valstr();
    const stepIntervals = this.state.intervals.map((step, i) => {
      return (
        <li key={i} style={{ bottom: `calc(${step.percent}% - 1px)` }}>
          {step.label}
        </li>
      );
    });

    const contenedorStyle = {
      //backgroundColor: 'lightgray', // Cambia el color de fondo según tus preferencias
      border: '1px solid #ccc', // Agrega un borde
      borderRadius: '5px', // Redondea las esquinas
    };

    const contenedor_padre = {
      backgroundColor: 'blue', // Cambia el color de fondo según tus preferencias
      border: '1px solid #ccc', // Agrega un borde
      borderRadius: '5px', // Redondea las esquinas
    };



    return (
      <Main background={this.state.background} className="widgets-font">
        <IconoEdit>
          <EditIcon onClick={this.funcion} onMouseOver={this.handleMouseOver} />
        </IconoEdit>

        {this.state.ultimoData != false ? (
          <>
            <Contenedor>
              {this.state.nombreWidget != false ? <Titulo>{this.state.nombreWidget}</Titulo> : null}
              {this.state.Mnombre ? <p>{this.state.nombeDiposositivo}</p> : null}
              <div className="thermometer-container">
                <div style={height} className={`thermometer ${size} ${theme}`}>
                  <div className="thermometer__draw-a"></div>
                  <div className="thermometer__draw-b"></div>
                  <div className="thermometer__meter">
                    <ul className="thermometer__statistics">{stepIntervals}</ul>
                    <div style={heightPercent} className="thermometer__mercury">
                      <div className="thermometer__percent-current">{valstr}</div>
                      <div className="thermometer__mask">
                        <div className="thermometer__bg-color" style={heightBgColor}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <NombreD>{this.state.Mdevice ? `ID: ${this.state.idDispositivo}` : null}</NombreD>
              </div>
              <Actalizacion className='widgets-font'>Ultima actualizacion: {this.state.año.año} {this.state.año.hora}</Actalizacion>
            </Contenedor>
          </>
        ) : (
          <>
            <p>El dispositivo {this.state.nombeDiposositivo} con ID {this.state.idDispositivo} no ha reportado</p>
            <Titulo>{this.state.nombreWidget}</Titulo>
            <ContenedorWa>
              <p>
                No ha reportado
                <ErrorOutlineIcon/>
              </p>
            </ContenedorWa>
          </>
        )}
      </Main>
    );
  }

}

export default Thermometer;