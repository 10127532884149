import React, { useState, useEffect } from "react";
import Map from "./mapa";
import { useLocation } from "react-router-dom";
import Api from "../../services/api-service";
import AUTH from "../../services/auth-service"
import {
  ContenedorTitulo,
  Main,
  Titulo,
  ParaMapa,
} from "./AlcantarillaElements";

const styleMapa = {
  width: "70%",
  height: "60%",
};

const Index = () => {
  const [devices, setDevices] = useState([]);

  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const tipo = query.get("tipo");


  useEffect(async () => {
    console.log("ORGANIZAAAAAAAAAAAAAAAAa");
    console.log(obteOrganizacion);
    console.log("Localización");
    console.log(localizacion)
    const auth = await AUTH.getOrganizaciones()
    console.log(auth)

    let datas = ""

    auth.map((index) => {
      index.localizacion.map((locali) => {
        if (locali.localiza === localizacion) {
          locali.configuraciones.map((conf) => {
            if (conf.tipo === "mapa") {
              console.log(conf.catalogo)
              datas = datas + conf.catalogo + ","
            }
          })
        }
      })
    })

    if (datas.endsWith(",")) {
      datas = datas.slice(0, -1);
    }

    console.log(datas)

    if (localizacion === "sto_epconst_loca_2") {
      const bodyReq = { organizacion: obteOrganizacion, localizacion: localizacion };
      Api.viweStatusSiren(bodyReq).then((respuesta) => {
        if (respuesta) {
          setDevices(respuesta);
        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      Api.ubicacionDispo(obteOrganizacion, localizacion, datas).then(
        (respuesta) => {
          console.log(respuesta)
          if (respuesta.status === true) {
            console.log("estoy en el if")
            console.log(respuesta.data)
            setDevices(respuesta.data)
          } else {
            console.log("estoy en el else")
          }
        },
        (error) => { }
      );
    }
  }, [location.search]);

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Ubicación de los dispositivos</Titulo>
        </ContenedorTitulo>
        {devices.length ? (
          <ParaMapa>
            <Map devices={devices} styleMapa={styleMapa} />
          </ParaMapa>
        ) : (
          <p>No existe resultados</p>
        )}
      </Main>
    </>
  );
};

export default Index;
