import React from "react";
import { Line } from "react-chartjs-2";
import { Resizable } from 'react-resizable';
import 'chartjs-adapter-moment';
import { Contenedor, Main } from '../LineChart/ElementsDisplay';

const addFiveHours = (date) => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + 5);
    return newDate;
};

const processConnectionData = (info) => {
    const disconnects = info.map(item => ({
        fecha: addFiveHours(new Date(item.fechaDesconexion)),
        estado: 0 // Desconexión
    }));

    const connections = info.map(item => ({
        fecha: addFiveHours(new Date(item.fechaConexion)),
        estado: 1 // Conexión
    }));

    const allData = [...disconnects, ...connections].sort((a, b) => a.fecha - b.fecha);

    const groupedData = allData.reduce((acc, { fecha, estado }) => {
        const key = fecha.toISOString();
        if (!acc[key]) {
            acc[key] = { fecha, estado };
        } else {
            if (estado === 1) {
                acc[key].estado = 1;
            }
        }
        return acc;
    }, {});

    const labels = Object.values(groupedData).map(data => data.fecha);
    const dataValues = Object.values(groupedData).map(data => data.estado);

    return { labels, data: dataValues };
};

const LineTest = ({ info }) => {
    var options;
    var dataChart;
    var mensaje = "";

    if(info === 'La sirena nunca se ha desconectado'){
        return (
            <>
                <br/>
                <p>Este dispositivo nunca se ha desconectado</p>
            </>
        );

    }else{
        const { labels, data } = processConnectionData(info);
        mensaje = labels[labels.length - 1]?.toLocaleString();
        options = {
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'hour',
                        tooltipFormat: 'll HH:mm'
                    },
                },
                y: {
                    beginAtZero: true,
                    reverse: false,
                    ticks: {
                        stepSize: 1,
                        callback: (value) => (value === 0 ? 'Desconectada' : 'Conectada'),
                        padding: 10
                    }
                }
            },
            maintainAspectRatio: false,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const estado = context.raw === 0 ? 'Desconectada' : 'Conectada';
                            return `Estado: ${estado}`;
                        }
                    }
                }
            }
        };

        dataChart = {
            labels: labels,
            datasets: [
                {
                    label: "Estado de la Sirena",
                    data: data,
                    fill: true,
                    borderWidth: 2,
                    pointRadius: 2, // Ocultar los puntos
                    backgroundColor: "rgba(75,192,192,0.2)",
                    borderColor: "rgba(75,192,192,1)",
                    stepped: true // Crear un gráfico escalonado
                }
            ]
        };
        
    }

    return (
        <>
            {info.length > 0 ? (
                <Main>
                    <Contenedor>
                        <Resizable>
                            <Line data={dataChart} options={options} />
                        </Resizable>
                    </Contenedor>
                    {mensaje && <p>Último registro de conexión: {mensaje}</p>}
                </Main>
            ) : (
                <p>No hay Datos</p>
            )}
        </>
    );
};

export default LineTest;
