import React from "react";
import { Redirect, Link } from "react-router-dom";
import Auth from "../../services/auth-service.js";
import { FaList, FaLocationArrow, FaRegChartBar, FaFilter, FaRegBuilding, FaLayerGroup, FaTemperatureHigh, FaFile } from "react-icons/fa";
// import { LuBellRing } from "react-icons/lu";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { MdDevicesOther } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import {
  humitemp,
  betatemp,
  cadenaFrio,
  cacaoBeta,
  gabinetes,
  LGT92,
  Alcantarilla,
  Calibracion,
  Wt32,
  Administracion,
  UbicacionSensores,
  EventosAlertas,
  Dispositivos,
  Procesos,
  Productos,
  Dashboard,
  Mapa,
  Sirenas,
  ReporteSirenas,
  ReporteAutomatico,
  MonitoreoGeneral,
  LisDispo,
  MoniTemp,
  Alertas,
  Incidencias,
  Filtro
} from "../../menu";
import "./SidebarMenu.scss";

export class SidebarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: this.props.menu,
      menuUser: this.props.menuUser,
      collapsed: false,
      rol: Auth.getRol(),

    };
  }

  getLink(props) {
    return Auth.getJWT() !== null ? (
      <props.component {...props} />
    ) : (
      <Redirect from="/" to="/login/ingreso" />
    );
  }

  getHabilitation(value) {
    if (Auth.getHabilitations()) {
      return Auth.getHabilitations().indexOf(value) !== -1;
    } else {
      return false;
    }
  }

  setStatusCollapsed(value) { }

  sidebarClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  getSubmenu = (element, name, oranizacion, localizacion) => {

    // //console.log(element)
    // //console.log(name)
    // //console.log(oranizacion)
    // //console.log(tipo)
    // //console.log(localizacion)
    if (!element) {
      return;
    }

    let item;
    if (element.modulo === "humitemp") {
      item = humitemp;
    }
    if (element.modulo === "betatemp") {
      item = betatemp;
    }
    if (element.modulo === "cadena frio") {
      item = cadenaFrio;
    }
    if (element.modulo === "cacao beta") {
      item = cacaoBeta;
    }
    if (element.modulo === "gabinetes") {
      item = gabinetes;
    }
    if (element.modulo === "LGT92") {
      item = LGT92;
    }
    if (element.modulo === "Alcantarilla") {
      item = Alcantarilla;
    }
    if (element.modulo === "Calibracion") {
      item = Calibracion;
    }
    if (element.modulo === "Wt32") {
      item = Wt32;
    }
    if (element.modulo === "Administracion") {
      item = Administracion;
    }


    let iconoD

    if (name == "Grupos") {
      iconoD = <FaLayerGroup />
    }
    else if (name == "Reporte") {
      iconoD = <HiOutlineDocumentReport />
    }
    else if (name == "Ubicacion Sensores") {
      iconoD = <FaList />
    }
    else {
      iconoD = <FaList />
    }

    return (
      <SubMenu
        key={name}
        title={name}
        icon={iconoD}
        className="submenuStyle"
      >
        {item.children.map((element) => {
          return (
            // <MenuItem key={element.name} className="styleMenuItem">{element.name}<Link to={element.layout + element.path}/></MenuItem>
            <MenuItem key={element.name} className="styleMenuItem">
              {element.name}
              <Link
                to={{
                  pathname: `${element.layout}${element.path}`,
                  search: `?organizacion=${oranizacion}&localizacion=${localizacion}`,
                }}
              />
            </MenuItem>
          );
        })}
      </SubMenu>
    );
  };

  getLinkMenu = (modulo, name, oranizacion, localizacion) => {

    //console.log(modulo)
    //console.log(name)
    //console.log(oranizacion)
    //console.log(localizacion)
    if (!modulo) {
      return;
    }

    let item;
    if (modulo === "humitemp") {
      item = humitemp;
    }
    if (modulo === "betatemp") {
      item = betatemp;
    }
    if (modulo === "cadena frio") {
      item = cadenaFrio;
    }
    if (modulo === "cacao beta") {
      item = cacaoBeta;
    }
    if (modulo === "gabinetes") {
      item = gabinetes;
    }
    if (modulo === "LGT92") {
      item = LGT92;
    }
    if (modulo === "Alcantarilla") {
      item = Dashboard;
    }
    if (modulo === "Calibracion") {
      item = Calibracion;
    }
    if (modulo === "Wt32") {
      item = Wt32;
    }
    if (modulo === "Administracion") {
      item = Administracion;
    }
    if (modulo === "UbicacionSensores") {
      item = UbicacionSensores;
    }
    if (modulo === "EventosAlertas") {
      item = EventosAlertas;
    }
    if (modulo === "ProcesosLista") {
      item = Procesos;
    }
    if (modulo === "Productos") {
      item = Productos;
    }
    if (modulo === "Dispositivos") {
      item = Dispositivos;
    }
    if (modulo === "UbicacionSensoresMapas") {
      item = Mapa;
    }
    if (modulo === "Dispositivos") {
      item = Sirenas
    }
    if (modulo === "MonitoreoGeneral") {
      item = MonitoreoGeneral
    }
    if (modulo === "ListDispositivos") {
      item = LisDispo
    }
    if (modulo === "ReporteSirenas") {
      item = ReporteSirenas
    }
    if (modulo === "ReporteAutomatico") {
      item = ReporteAutomatico
    }
    if (modulo === "ListTemp") {
      item = MoniTemp
    }
    if (modulo === "ListAlertas") {
      item = Alertas
    }
    if (modulo === "ListIncidencias") {
      item = Incidencias
    }
    if (modulo === "filtro") {
      item = Filtro
    }

    let iconoD

    if (name == "Dashboard") {
      iconoD = <FaRegChartBar />
    }
    else if (name == "Grupos") {
      iconoD = <FaLayerGroup />
    }
    else if (name == "Reporte") {
      iconoD = <HiOutlineDocumentReport />
    }
    else if (name == "Ubicacion Sensores") {
      iconoD = <FaList />
    }
    else if (name == "Eventos Alertas") {
      iconoD = <FaLayerGroup />
    }
    else if (name == "Procesos") {
      iconoD = <FaLayerGroup />
    }
    else if (name == "Productos") {
      iconoD = <FaLayerGroup />
    }
    else if (name == "Dispositivos") {
      iconoD = <FaLayerGroup />
    }
    else if (name == "Reporte Sirenas") {
      iconoD = <FaFile />
    }
    else if (name == "Reporte Automatico") {
      iconoD = <FaFile />
    }
    else if (name == "Temperaturas") {
      iconoD = <FaTemperatureHigh />
    }
    else if (name == "Filtrar por horas") {
      iconoD = <FaFilter />
    }
    else {
      iconoD = <FaList />
    }

    return (
      <MenuItem key={name} className="submenuStyle">
        <span style={{ marginRight: '10px' }}>{iconoD}</span>
        <span>{name}</span>

        <Link
          to={{
            pathname: `${item.layout}${item.path}`,
            search: `?organizacion=${oranizacion}&localizacion=${localizacion}`,
          }}
        />
      </MenuItem>
    );



  };

  render() {
    return (
      <div id="sidebar-menu" className="estilo-sidebar">
        <ProSidebar
          className="sidebar-style"
          collapsed={this.state.collapsed}
          onToggle={this.setStatusCollapsed}
        >
          <img
            className="logo-img logo-aiot"
            src={require("../../assets/img/aiot_logo.png").default}
            onClick={this.sidebarClick}
            alt="..."
          />
          <Menu iconShape="square">
            {this.state.menu.map((item) => {
              //carga el modulo de menu
              if (item.state !== undefined && item.type !== "model") {
                if (item.name !== "General") {
                  if (this.state.rol !== "administrador general") {
                    return;
                  }
                }

                return (
                  <SubMenu
                    key={item.name}
                    title={item.name}
                    icon={item.icon}
                    className="submenuStyle"
                  >
                    {item.children !== undefined &&
                      item.children.map((element) => {
                        return (
                          <MenuItem
                            key={element.name}
                            className="styleMenuItem"
                          >
                            {element.name}
                            <Link
                              to={{
                                pathname: `${element.layout}${element.path}`,
                                search: `?organizacion=${item.name}`,
                              }}
                            />
                          </MenuItem>
                        );
                      })}
                  </SubMenu>
                );
              }
            })}


            {this.state.menuUser.map((item) => {
              if (this.state.rol === "administrador") {
                let organizacion
                //carga el modulo de menu
                if (Auth.getOrganizaciones().length == 1) {
                  {/* { //console.log('Aqui esta pasando') }
                  { //console.log(item) } */}
                  let orga = Auth.getOrganizaciones()
                  organizacion = orga[0].organizacion
                  return (
                    <SubMenu
                      key={item.name}
                      title={item.name}
                      icon={item.icon}
                      className="submenuStyle"
                    >
                      {item.children !== undefined &&
                        item.children.map((element) => {
                          return (
                            <MenuItem
                              key={element.name}
                              className="styleMenuItem"
                            >
                              {element.name}
                              <Link
                                to={{
                                  pathname: `${element.layout}${element.path}`,
                                  search: `?organizacion=${organizacion}`,
                                }}
                              />
                            </MenuItem>
                          );
                        })}
                    </SubMenu>
                  );
                }
              }
            })}


            {Auth.getOrganizaciones().map((item, ite) => {
              //carga organizaciones
              if (!item) {
                return;
              }

              return (
                <SubMenu
                  key={ite}
                  title={item.nombre}
                  icon={<FaRegBuilding />}
                  className="submenuStyle"
                >
                  {item.localizacion.map((elemento) => {
                    { console.log(elemento) }
                    return (
                      <SubMenu
                        key={elemento.nombre}
                        className="submenuStyle"
                        icon={<FaLocationArrow />}
                        title={elemento.nombre}>

                        {elemento.dashboard != undefined && elemento.dashboard != null && elemento.dashboard.length > 0 ? (this.getLinkMenu(
                          elemento.dashboard[0].modulo,
                          elemento.dashboard[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }

                        {elemento.ubicacionD != undefined && elemento.ubicacionD != null && elemento.ubicacionD.length > 0 ? (this.getLinkMenu(
                          elemento.ubicacionD[0].modulo,
                          elemento.ubicacionD[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }

                        {elemento.ubicacionMapas != undefined && elemento.ubicacionMapas != null && elemento.ubicacionMapas.length > 0 ? (this.getLinkMenu(
                          elemento.ubicacionMapas[0].modulo,
                          elemento.ubicacionMapas[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }


                        {elemento.eventos != undefined && elemento.eventos != null && elemento.eventos.length > 0 ? (this.getLinkMenu(
                          elemento.eventos[0].modulo,
                          elemento.eventos[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }

                        {elemento.procesos != undefined && elemento.procesos != null && elemento.procesos.length > 0 ? (this.getLinkMenu(
                          elemento.procesos[0].modulo,
                          elemento.procesos[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }
                        {elemento.dispositivos != undefined && elemento.dispositivos != null && elemento.dispositivos.length > 0 ? (this.getLinkMenu(
                          elemento.dispositivos[0].modulo,
                          elemento.dispositivos[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }

                        {elemento.productos != undefined && elemento.productos != null && elemento.productos.length > 0 && this.state.rol === "administrador" ? (this.getLinkMenu(
                          elemento.productos[0].modulo,
                          elemento.productos[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }

                        {elemento.listaDispositivos != undefined && elemento.listaDispositivos != null && elemento.listaDispositivos.length > 0 ? (this.getLinkMenu(
                          elemento.listaDispositivos[0].modulo,
                          elemento.listaDispositivos[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }

                        {elemento.monitoreoGeneral != undefined && elemento.monitoreoGeneral != null && elemento.monitoreoGeneral.length > 0 ? (this.getLinkMenu(
                          elemento.monitoreoGeneral[0].modulo,
                          elemento.monitoreoGeneral[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }
                        {elemento.reporteAutomatico != undefined && elemento.reporteAutomatico != null && elemento.reporteAutomatico.length > 0 ? (this.getLinkMenu(
                          elemento.reporteAutomatico[0].modulo,
                          elemento.reporteAutomatico[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }
                        {elemento.reporteSirenas != undefined && elemento.reporteSirenas != null && elemento.reporteSirenas.length > 0 ? (this.getLinkMenu(
                          elemento.reporteSirenas[0].modulo,
                          elemento.reporteSirenas[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        )) : null
                        }
                        {elemento.listTemp != undefined && elemento.listTemp != null &&
                          elemento.listTemp.length > 0 ? (this.getLinkMenu(
                            elemento.listTemp[0].modulo,
                            elemento.listTemp[0].nombre,
                            item.organizacion,
                            elemento.localiza
                          )) : null
                        }

                        {elemento.alertas != undefined && elemento.alertas != null &&
                          elemento.alertas.length > 0 ? (this.getLinkMenu(
                            elemento.alertas[0].modulo,
                            elemento.alertas[0].nombre,
                            item.organizacion,
                            elemento.localiza
                          )) : null
                        }

                        {elemento.incidencia != undefined && elemento.incidencia != null &&
                          elemento.incidencia.length > 0 ? (this.getLinkMenu(
                            elemento.incidencia[0].modulo,
                            elemento.incidencia[0].nombre,
                            item.organizacion,
                            elemento.localiza
                          )) : null
                        }

                        {elemento.filtro != undefined && elemento.filtro != null &&
                          elemento.filtro.length > 0 ? (this.getLinkMenu(
                            elemento.filtro[0].modulo,
                            elemento.filtro[0].nombre,
                            item.organizacion,
                            elemento.localiza
                          )) : null
                        }

                        {/* 
                        {elemento.ubicacionD != undefined && elemento.ubicacionD != null && elemento.ubicacionD.length > 0 ? (<MenuItem
                          key="ubicacion"
                          className="styleMenuItem"
                        >
                          {<FaList />} Ubicacion Sensores
                          <Link
                            to={{
                              pathname: `/admin/ubicacionSensores`,
                              search: `?organizacion=${item.organizacion}&localizacion=${elemento.localiza}`,
                            }}
                          />
                        </MenuItem>) : null
                        } */}

                        {/* 
                        {elemento.grupos.length > 1 ? null : (this.getSubmenu(
                          elemento.grupos[0],
                          elemento.grupos[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        ))
                        }


                        {elemento.reporte.length > 1 ? null : (this.getSubmenu(
                          elemento.reporte[0],
                          elemento.reporte[0].nombre,
                          item.organizacion,
                          elemento.localiza
                        ))
                        } */}


                        {/* <SubMenu
                          key={elemento.nombre}
                          className="submenuStyle"
                          icon={<MdDevicesOther />}
                          title="Dispositivos">
                          {elemento.configuraciones !== undefined &&
                            elemento.configuraciones.map((element) => {
                              return this.getSubmenu(
                                element,
                                element.nombre,
                                item.organizacion,
                                elemento.localiza
                              );
                            })}
                        </SubMenu> */}
                        {/* <SubMenu
                          key={elemento.nombre}
                          className="submenuStyle"
                          icon={<MdDevicesOther />}
                          title="Ubicanción Sensores">
                          {elemento.configuraciones !== undefined &&
                            elemento.configuraciones.map((element) => {
                              return this.getSubmenu(
                                element,
                                element.nombre,
                                item.organizacion,
                                elemento.localiza
                              );
                            })}
                        </SubMenu> */}

                      </SubMenu>
                    )
                  })}
                </SubMenu>
              );

            })}
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}

export default SidebarMenu;
