import React, { useEffect, useState } from "react";
import Api from "../../../services/api-service.js";
import { useLocation, useHistory } from "react-router-dom";
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Swal from "sweetalert2";
import {
    CustomContainer,
    CustomTitle,
    CustomSubtitle,
    CustomGroup,
    Label,
    CustomInput,
    CustomTextarea,
    CustomCheckbox,
    CustomAddButton,
    CustomRemoveButton,
    CustomSaveButton,
    CustomBackButton,
    CustomLoading
} from "./estilos.js"; // Ajusta la ruta según sea necesario

const Index = () => {
    const [alerta, setAlerta] = useState(null);
    const [newPhone, setNewPhone] = useState("");
    const [newMail, setNewMail] = useState("");
    const query = new URLSearchParams(useLocation().search);
    const idAlerta = query.get("id");
    const organizacion = query.get("organizacion");
    const sucursal = query.get("localizacion");
    const history = useHistory();
    const [temporizador, setTemporizador] = useState("");
    const [isToggled, setIsToggled] = useState();
    const [error, setError] = useState(false);

    const atras = () => {
        history.goBack();
    };

    useEffect(() => {
        let DocumentoB = {
            alerta: idAlerta,
        };

        Api.buscarConfiguraciones(DocumentoB).then(
            (response) => {
                setAlerta(response);
                if(response.temporizador === 0){
                    setIsToggled(false)
                    setTemporizador("")
                }else{
                    setIsToggled(true)
                    setTemporizador(response.temporizador)
                }
            },
            (error) => {
                console.log("Obtencion erronea");
            }
        );
    }, [idAlerta]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setAlerta({
            ...alerta,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handlePhoneChange = (index, event) => {
        const updatedPhones = [...alerta.phones];
        updatedPhones[index].number = event.target.value;
        setAlerta({ ...alerta, phones: updatedPhones });
    };

    const handleMailChange = (index, event) => {
        const updatedMails = [...alerta.mails];
        updatedMails[index] = event.target.value;
        setAlerta({ ...alerta, mails: updatedMails });
    };

    const addPhone = () => {
        setAlerta({
            ...alerta,
            phones: [...alerta.phones, { number: newPhone }],
        });
        setNewPhone("");
    };

    const removePhone = (index) => {
        const updatedPhones = alerta.phones.filter((_, i) => i !== index);
        setAlerta({ ...alerta, phones: updatedPhones });
    };

    const addMail = () => {
        setAlerta({
            ...alerta,
            mails: [...alerta.mails, newMail],
        });
        setNewMail("");
    };

    const removeMail = (index) => {
        const updatedMails = alerta.mails.filter((_, i) => i !== index);
        setAlerta({ ...alerta, mails: updatedMails });
    };

    const handleToggle = (checked) => {
        setIsToggled(checked.target.checked);
        if (!checked.target.checked) {
            setTemporizador(''); 
            setError(false);  
            setAlerta({ ...alerta, temporizador: 0});
        }
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        const timeValue = Number(value);
        // Verifica que el valor sea un número positivo
        if (!isNaN(value) && Number(value) >= 0) {
            console.log("este es el valor del temporizador", value)
            setTemporizador(timeValue);
            setAlerta({ ...alerta, temporizador: timeValue});
            setError(false);
        } else {
            setError(true);
        }
    }

    const saveChanges = () => {
        console.log(alerta);
        Api.actualizarAlerta(alerta).then(
            (response) => {
                Swal.fire("Guardado exitosamente", "", "success");
                history.push(
                    `/admin/events?organizacion=${organizacion}&localizacion=${sucursal}`
                );
            },
            (error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Selecciona una variable primero",
                });
            }
        );
    };

    if (!alerta) return <CustomLoading>Cargando...</CustomLoading>;

    return (
        <CustomContainer>
            <CustomTitle>Editar Alerta</CustomTitle>
            <CustomGroup>
                <Label>Nombreeee:</Label>
                <CustomInput
                    type="text"
                    name="nombre"
                    value={alerta.nombre}
                    onChange={handleChange}
                />
            </CustomGroup>
            <CustomGroup>
                <Label>Mensaje Trigger:</Label>
                <CustomTextarea
                    name="mensajeTriiger"
                    value={alerta.mensajeTriiger}
                    onChange={handleChange}
                />
            </CustomGroup>
            <CustomGroup>
                <Label>Mensaje Then:</Label>
                <CustomTextarea
                    name="messageThen"
                    value={alerta.messageThen}
                    onChange={handleChange}
                />
            </CustomGroup>
            <CustomGroup>
                <Label>Estado Alerta:</Label>
                <CustomCheckbox
                    name="estado"
                    checked={alerta.estado}
                    onChange={handleChange}
                />
            </CustomGroup>
            <CustomGroup>
                <Label>Temporizador Alerta:</Label>
                <FormControlLabel
                    control={<Switch checked={isToggled} onChange={handleToggle} />}
                    label="Activa si deseas que la alerta no se envié inmediatamente"
                />

                {/* Input condicional */}
                {isToggled && (
                    <TextField
                        label="Temporizador"
                        variant="outlined"
                        placeholder="Tiempo en min"
                        value={temporizador}
                        onChange={handleInputChange}
                        error={error}
                        helperText={error ? "Por favor ingrese un número positivo." : ""}
                        sx={{ mt: 2, width: '25%' }}
                    />
                )}
            </CustomGroup>
            <CustomSubtitle>Teléfonos</CustomSubtitle>
            {alerta.phones.map((phone, index) => (
                <CustomGroup key={index}>
                    <CustomInput
                        type="text"
                        value={phone.number}
                        onChange={(event) => handlePhoneChange(index, event)}
                    />
                    <CustomRemoveButton onClick={() => removePhone(index)}>
                        Eliminar
                    </CustomRemoveButton>
                </CustomGroup>
            ))}
            <CustomGroup>
                <CustomInput
                    type="text"
                    value={newPhone}
                    onChange={(event) => setNewPhone(event.target.value)}
                />
                <CustomAddButton onClick={addPhone}>Agregar Teléfono</CustomAddButton>
            </CustomGroup>
            <CustomSubtitle>Mails</CustomSubtitle>
            {alerta.mails.map((mail, index) => (
                <CustomGroup key={index}>
                    <CustomInput
                        type="text"
                        value={mail}
                        onChange={(event) => handleMailChange(index, event)}
                    />
                    <CustomRemoveButton onClick={() => removeMail(index)}>
                        Eliminar
                    </CustomRemoveButton>
                </CustomGroup>
            ))}
            <CustomGroup>
                <CustomInput
                    type="text"
                    value={newMail}
                    onChange={(event) => setNewMail(event.target.value)}
                />
                <CustomAddButton onClick={addMail}>Agregar Mail</CustomAddButton>
            </CustomGroup>
            <CustomSaveButton onClick={saveChanges}>Guardar</CustomSaveButton>
            <CustomBackButton onClick={atras}>Atrás</CustomBackButton>
        </CustomContainer>
    );
};

export default Index;