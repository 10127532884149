import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Select1 from './../../../components/Selects/Select1';
import { Main, ContenedorTitulo } from './viewOneElements';
import './viewOneElements.css';
import Swal from 'sweetalert2'
import Table from './table/table'
import { useLocation } from 'react-router-dom'
import AUTH from "../../../services/auth-service";
import API from "../../../services/api-service";
import { ContenedorCategorias } from '../DispositivosElements';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from "@mui/material/MenuItem";
import Stack from '@mui/material/Stack';

const ViewOne = (props) => {
    const [showBox, setShowBox] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [soloVariable, setSoloVariables] = useState({})
    const [select, setSelect] = useState("")
    const [recibirDevice, setRecibirDevice] = useState("")
    const [nombreDevice, setNombreDevice] = useState("")
    const [textoV, setTextoV] = useState("");
    const [isClicked, setIsClicked] = useState(true);
    const query = new URLSearchParams(useLocation().search);
    const organizacionn = query.get("organizacion");
    const localizacionn = query.get("localizacion");
    const deviceID = query.get("deviceID");
    const [opcionSeleccionada, setOpcionSeleccionada] = useState('crear');
    const [inputHabilitado, setInputHabilitado] = useState(true);
    const [name, setName] = useState('');
    const [nuevoSelect, setNuevoSelect] = useState('');
    const [reload, setReload] = useState(false);

    const handleChangee = (event) => {
        const opcion = event.target.name;
        setOpcionSeleccionada(opcion);

        if (opcion === 'crear') {
            setInputHabilitado(true);
        } else if (opcion === 'modificar') {
            setInputHabilitado(false);
        }
    };

    const validarTexto = (data) => {
        if (!data || data == null || data == '') {
            Swal.fire({
                title: "Error al Guardar",
                text: "Campos no válidos",
                icon: "error",
                showCancelButton: true,
                confirmButtonColor: "#1976d2",
                cancelButtonColor: "#d33",
            }).then((event) => {
                if (event.isConfirmed) {
                    //POSSSSSSSSSSST
                    console.log('falta hacer el post');
                }
            })
        }
    }

    const enviarVariable = async () => {
        if (opcionSeleccionada == 'modificar') {
            let Data = {
                organizacion: organizacionn,
                localizacion: localizacionn,
                deviceID: deviceID,
                nombreVariable: select,
                operacion: textoV
            };
            try {
                if (Data.organizacion && Data.localizacion && Data.deviceID && Data.operacion) {
                    validarTexto(Data)
                    const response = await API.subirVariableSintetica(Data)
                    setReload(!reload)
                    console.log(response, 'response');
                    console.log(Data, 'Data');
                    console.log(dataTable, 'dataTabke');
                }
            } catch (error) {
                console.error('Error al enviar los datos:', error);
            }
        }
        else if (opcionSeleccionada == 'crear') {
            let Data = {
                organizacion: organizacionn,
                localizacion: localizacionn,
                deviceID: deviceID,
                nombreVariable: name,
                operacion: textoV
            };
            try {
                if (Data.organizacion && Data.localizacion && Data.deviceID && Data.operacion) {
                    const response = await API.subirVariableSintetica(Data)
                    setReload(!reload)
                    console.log(response);
                }
            } catch (error) {
                console.error('Error al enviar los datos:', error);
            }
        }
    }

    const ConsumirDispositivo = async () => {
        const recibirDevicee = AUTH.getVariablesSinteticas()
        let arregloDefinitivo = []
        recibirDevicee.variables.map((index, _) => {
            let documento = {}
            documento.label = index
            documento.value = index
            arregloDefinitivo.push(documento)
        })

        setNuevoSelect(recibirDevice)
        setSoloVariables(arregloDefinitivo)
        setRecibirDevice(recibirDevicee)
        let Data = {
            organizacion: organizacionn,
            localizacion: localizacionn,
            deviceID: deviceID
        };
        try {
            const response = await API.obtenerTablaVariables(Data);
            
            setDataTable(response)
        } catch (error) {
            console.error('Error al obtener la tabla de variables:', error);
        }
    };

    const probandoLog = (value) => {
        console.log("Aqui llega");
        console.log(value);
        setSelect(value)
        const asd = `@/${value}/@`
        const testinggg = textoV + asd
        setTextoV(testinggg)
    }

    const guardar = () => {
        Swal.fire({
            title: "Confirmar operación",
            text: "Guardar Variables Sintéticas",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#1976d2",
            cancelButtonColor: "#d33",
        }).then((event) => {
            if (event.isConfirmed) {
                setIsClicked(false)
                setShowBox(true)
                if (inputHabilitado && name !== '' && textoV !== '') {
                    Swal.fire({ title: "Variable Creada", icon: "success", confirmButtonColor: "#1976d2", }).then(() => {
                        enviarVariable()
                    });
                }

                else if (inputHabilitado == false && textoV !== '') {
                    Swal.fire({ title: "Variable Creada", icon: "success", confirmButtonColor: "#1976d2", }).then(() => {
                        enviarVariable()
                    });
                }

                else {
                    Swal.fire({ title: "Variable no se pudo crear", icon: "error", confirmButtonColor: "#FF0000", }).then(() => {
                        enviarVariable()
                    });
                }
            }
        })
    }

    const handleSubmit = (event) => {
        delete soloVariable[0]
        delete soloVariable[1]
        delete soloVariable[2]
        delete soloVariable[3]
        delete soloVariable[4]
        delete soloVariable[5]
        // console.log(soloVariable,'solooooo');

        event.preventDefault();
        Swal.fire({
            title: "Confirmar operación",
            text: "Creación de Variables Sintéticas",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#1976d2",
            cancelButtonColor: "#d33",

        }).then((event) => {
            if (event.isConfirmed) {

                setIsClicked(false)
                setShowBox(true);
            }
        });
    };

    const handleKeyDown = (event) => {
        const teclasPermitidasRegex = /^[+\-*/\s1234567890.()@]|Backspace$|^Arrow(Up|Down|Left|Right)$/;
        if (!teclasPermitidasRegex.test(event.key)) {
            event.preventDefault();
        }
    };

    const funcionTexto = (value) => {
        setTextoV(value)
    }

    useEffect(() => (
        ConsumirDispositivo()
    ), [])


    useEffect(() => {
        ConsumirDispositivo()
    }, [reload])

    const actualizar = () =>{
        if(recibirDevice.name != nombreDevice){
            recibirDevice.name = nombreDevice
        }
        console.log(recibirDevice.name)
        ConsumirDispositivo()
    }

    const cambiarNombre = (e) =>{
        const inputValue = e.target.value;
        setNombreDevice(inputValue)
    }

    return (
        <Main>
            <ContenedorTitulo>
                DATOS DEL DISPOSITIVO
            </ContenedorTitulo>
            <ContenedorCategorias>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '0 0 45%', padding: '0 60px' }}>
                        <TextField                            
                            id="outlined-basic"
                            label='Nombre'
                            value={recibirDevice.name}
                            onChange={cambiarNombre}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            disabled
                            id="outlined-basic"
                            label='ID dispositivo'
                            value={recibirDevice.deviceID}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            disabled
                            id="outlined-basic"
                            label='Último reporte'
                            value={recibirDevice.ultimoReporte}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            disabled
                            id="outlined-basic"
                            label='Localización'
                            value={organizacionn}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <div style={{ margin: '10px -1px' }}>

                            {isClicked ? <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}  >
                                Crear Variables Sintéticas
                            </Button> : <Button variant="outlined" color="error" >
                                Crear Variables Sintéticas
                            </Button>}                            
                        </div>
                        <div>
                            <Button variant="contained" onClick={() => actualizar()}>Editar Dispositivo</Button>
                        </div>
                    </div>
                    <div>
                        {showBox ? (
                            <>
                                <div>
                                    <FormControlLabel control={
                                        <Checkbox defaultChecked checked={opcionSeleccionada === 'crear'} onChange={handleChangee} name="crear" />
                                    } label="Crear variables" />

                                    <FormControlLabel control={
                                        <Checkbox checked={opcionSeleccionada === 'modificar'} onChange={handleChangee} name="modificar" />
                                    } label="Modificar variable existente" />
                                </div>
                                <div style={{ margin: '10px -8px' }}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '30ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField
                                            id="outlined-basic"
                                            label="Nombre de Variable"
                                            variant="outlined"
                                            onChange={(event) => {
                                                setName(event.target.value);
                                            }}
                                            value={name}
                                            disabled={!inputHabilitado} />
                                    </Box>
                                    <Box
                                        component="form"
                                        sx={{
                                            "& .MuiTextField-root": { m: 1, width: "30ch" },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Variables del Dispositivo"
                                            value={select}
                                            onChange={(e) => probandoLog(e.target.value)}
                                        >
                                            {soloVariable.map((option) => (
                                                <MenuItem key={option.label} value={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div style={{ margin: '10 10px' }}>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Variables"
                                                multiline
                                                rows={4}
                                                value={textoV}
                                                onChange={(e) => funcionTexto(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                                placeholder="Solo +, -, *, / o Borrar"
                                            />
                                            <Stack direction="row" spacing={2} style={{ marginLeft: '10px' }}>
                                                <Button variant="contained" color="primary" onClick={(e) => guardar()}>
                                                    Guardar
                                                </Button>
                                            </Stack>
                                        </div>
                                    </Box>
                                </div>
                            </>
                        ) : <></>}
                    </div>
                </div>
                <div style={{ margin: '14px' }}>
                    <ContenedorTitulo>
                        Variables Creadas
                    </ContenedorTitulo>
                    <div>

                    </div>
                    <Table rows={dataTable} />
                </div>
            </ContenedorCategorias>

        </Main>
    );
};

export default ViewOne;