import React, { useState, useMemo, useEffect } from 'react'
import TextField from "@mui/material/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Informa from "../../Informacion/index";
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { SketchPicker as Picker } from 'react-color';
import { ContenedorTitulo, Main, Opciones, BotonGuardar } from '../Elements'

const LineTest = ({ data, valorWid, device, variable, variableElegida, showModalVariable, DispositivoE, saveData }) => {

    const [tituloWidget, setTituloWidget] = useState("")
    const [vNombreDispotivo, setVNombreDispotivo] = useState(false)
    const [vIdDispositivo, setVIdDispositivo] = useState(false)
    const [tiempoReporte, setTiempoReporte] = useState(1440)
    const [backgroundColor, setBackgroudColor] = useState("#ffffff")
    const [checkColor, setColor] = useState(false)
    const [agregacion, setAgregacion] = useState("Max")
    const [tipo, setTipo] = useState("test")
    const [variables, setVariables] = useState([])
    const [devices, setDevices] = useState([])
    const [dispositivos, setDispositivos] = useState([])


    const GuardarData = () => {
        console.log(variableElegida)
        console.log(variable)
        console.log(device)
        var nombreVariable = [variables[0].nombre]
        let Views = {
            "Mdevice": [false],
            "Mnombre": [false]
        }
        let maxY
        let cantidad
        let maxX
        if (Object.keys(data).length == 4) {
            maxY = 0
            cantidad = 1
            data.posiciones = []
            data.identificadores = []
        } else {
            maxY = data.posiciones[0].y;
            maxX = data.posiciones[0].x;
            cantidad = data.posiciones.length + 1;
            cantidad = cantidad.toString();

            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y > maxY) {
                    maxY = data.posiciones[i].y;
                }
            }
            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y == maxY) {
                    if (data.posiciones[i].x > maxX) {
                        maxX = data.posiciones[i].x;
                    }
                }
            }
        }

        if (maxX > 4) {
            maxX = 0
            maxY = maxY + 1
        } else {
            maxX = maxX + 1
        }

        let nombres = {
            name: valorWid,
            device: devices,
            variable: variables,
            typeDevice: dispositivos,
            reporte: Number(tiempoReporte),
            background: hexColor,
            agregacion: agregacion,
            tipo: tipo,
            nombre: nombreVariable
        };


        if (tituloWidget != "") {
            nombres.tituloWidget = tituloWidget
        }
        if (vNombreDispotivo) {
            Views.Mnombre.push(true)
        }
        if (vIdDispositivo) {
            Views.Mdevice.push(true)
        }
        nombres.viwes = Views
        let newWidget = {
            w: 11,
            h: 2,
            x: maxX,
            y: maxY,
            i: cantidad,
            moved: false,
            static: false,
        };
        data.posiciones.push(newWidget);
        data.identificadores.push(nombres);
        saveData(data)
        console.log("Esta tiene data.identificadores")
        console.log(data.identificadores)
        console.log("Esto tiene nombres")
        console.log(nombres)
    };

    useEffect(() => {
        if (device && !devices.includes(device)) {
            setDevices([device, ...devices]);
        }
    }, [device, devices]);

    useEffect(() => {
        if (variable && !variables.includes(variable)) {
            setVariables([variable, ...variables]);
        }
    }, [variable, variables]);

    useEffect(() => {
        if (DispositivoE && !dispositivos.includes(DispositivoE)) {
            setDispositivos([DispositivoE, ...dispositivos]);
        }
    }, [DispositivoE, dispositivos]);

    const VerificarTituloWidget = (e) => {
        const inputValue = e.target.value;
        setTituloWidget(inputValue)
    }

    const handleChange = (event) => {
        setVNombreDispotivo(event.target.checked);
    };
    const handleChangeD = (event) => {
        setVIdDispositivo(event.target.checked);
    };
    const handleChangeC = (event) => {
        setColor(event.target.checked);
    };

    const decimalToHex = (alpha) =>
        alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

    const hexColor = useMemo(() => {
        if (typeof backgroundColor === 'string') {
            return backgroundColor;
        }
        return `${backgroundColor?.hex}${decimalToHex(backgroundColor?.rgb?.a)}`;
    }, [backgroundColor]);

    return (
        <>
            <Main>
                <ContenedorTitulo>
                    Parámetros
                </ContenedorTitulo>
                <TextField
                    label="Titulo Widget"
                    value={tituloWidget}
                    onChange={VerificarTituloWidget}
                />
                <Opciones>
                    <FormControlLabel
                        control={
                            <Checkbox checked={vNombreDispotivo}
                                onChange={handleChange}
                                name="Nombre del Dispositivo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="Nombre del Dispositivo"
                    /><Informa
                        info="Si desea que el nombre del dispositivo aparezca en el widget, marque la casilla."
                    />
                </Opciones>
                <Opciones>

                    <FormControlLabel
                        control={
                            <Checkbox checked={vIdDispositivo}
                                onChange={handleChangeD}
                                name="ID Disposiivo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="ID Dispositivo"
                    />
                </Opciones>
                <Opciones>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Tiempo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={tiempoReporte}
                            label="Estado"
                            onChange={(e) => setTiempoReporte(e.target.value)}
                        >

                            <MenuItem value="1440">ultimas 24 horas</MenuItem>
                            <MenuItem value="10080">ultima semana</MenuItem>
                        </Select>
                    </FormControl>
                </Opciones>
                <Opciones>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Agregación</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={agregacion}
                            label="Estado"
                            onChange={(e) => setAgregacion(e.target.value)}
                        >

                            <MenuItem value="Max">Máximo</MenuItem>
                            <MenuItem value="Min">Mínimo</MenuItem>
                            <MenuItem value="Total">Suma</MenuItem>
                            <MenuItem value="Prom">Promedio</MenuItem>
                        </Select>
                    </FormControl>
                </Opciones>
                <Opciones>
                    <FormControlLabel
                        control={
                            <Checkbox checked={checkColor}
                                onChange={handleChangeC}
                                name="Color de fondo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="Color de fondo"
                    /><Informa
                        info="Si desea cambiar el color de fondo del widget marque esta casilla"
                    />
                </Opciones>
                {checkColor != false ?
                    <Opciones>

                        <Picker color={hexColor} onChange={setBackgroudColor} />
                    </Opciones>
                    : null}
                <Opciones>
                    <Button variant="contained" onClick={showModalVariable}>
                        Seleccionar variable
                    </Button>
                </Opciones>

                {Object.keys(variableElegida).length !== 0 ? (
                    <>
                        <h5>Selecciono el dispositivo con la variable ({variableElegida.variable}) del dispositivo ({variableElegida.nombre}) con ID ({variableElegida.deviceID}) </h5>
                    </>
                ) : null}
                {device != null && device != "" ? <BotonGuardar onClick={GuardarData}>Guardar</BotonGuardar> : null}
            </Main>
        </>
    )
}

export default LineTest
