import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import Api from '../../../services/api-service.js'

import React, { useState, useEffect } from 'react';

import './estilosFormularios.css';



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

export default function EditarUsuarios(props) {

  const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [organizacion, setOrganizacion] = React.useState(props.organizacion);
  const [nombre, setNombre] = React.useState(props.item.nombre);
  const [email, setEmail] = React.useState(props.item.email);
  // const [direccion, setDireccion] = React.useState(props.item.direccion);
  const [estado, setEstado ] = React.useState(props.item.estado);
  const [organizaciones, setOrganizaciones ] = React.useState(props.item.organizaciones);
  const [rol, setRol ] = React.useState(props.item.rol);
  const [habilitaciones, setHabilitaciones ] = React.useState(props.item.habilitaciones);
  const [password, setPassword ] = React.useState(null);

  const [tipoEditar, setTipoEditar ] = React.useState(props.tipoEditar);
  const [nombreEditar, setNombreEditar ] = React.useState(props.nombreEditar);
  
  const [value, setValue ] = React.useState(null);



  

  const [listaEmpresas, setListaEmpresas] = React.useState([]);


  const [form, setForm ] = React.useState({usuario: props.item.usuario});

  
  useEffect(() => {

  // setNombre(props.item.nombre)
  // setEmail(props.item.email)
  // setDireccion(props.item.direccion)
  // setCantidadUsuarios(props.item.cantidadUsuarios)
  // setEstado(props.item.estado)

  },[props]);
  

  function openModal() {
    setIsOpen(true);
    // console.log(tipo)
  }

  function closeModal() {
    console.log('closeModal')
   setIsOpen(false);
   reset();
  }

  function reset(){
    // setNombre(props.item.nombre)
    // setEmail(props.item.email)
    // setDireccion(props.item.direccion)
    // setCantidadUsuarios(props.item.cantidadUsuarios)
    // setEstado(props.item.estado)
  }
  
  function afterOpenModal() {
    console.log('afterOpenModal')
    console.log(props)

    actualizarListaEmpresas()

  }

  function handleChangeForm(event){
    console.log(event.target.name)

    form[event.target.name] = (event.target.value === 'null')? null: event.target.value ;

    if(event.target.name === 'nombre'){setNombre(event.target.value)}
    if(event.target.name === 'email'){setEmail(event.target.value)}
    if(event.target.name === 'password'){setPassword(event.target.value)}
    if(event.target.name === 'direccion'){setPassword(event.target.value)}


    console.log(form)
  }

  function submitForm(){
    console.log('envio info', form);
    Api.actualizacionDatosUsuario(form).then(
      result =>{
        console.log(result)

          // alert(result.mensaje)
          props.resultCallback(result.mensaje);
          closeModal();

      }, error=>{
        alert(error)
        console.log(error.error)

      }
    )
  }


  /////////////////////////////////////////////////////////////////  consumos ///////////////////////////////////
  function actualizarListaEmpresas(){
    Api.obtenerListaEmpresas({}).then(
      result=>{
        console.log(result);
        setListaEmpresas(result);
      }, error=>{
        console.log('error en la adquisicion de datos', error);
      }
    )
  }
  
  

  return (


    <div>
      <button onClick={openModal}>Editar</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <h1 >Editar Usuario</h1>
          <div className=''>
          <div className='formulario'>
             <label>{nombreEditar}</label>
             <input type="text" name={tipoEditar}  value={value} onChange={handleChangeForm}/>
          </div>
          
          {/* {(tipoEditar == 'email' ) &&<div className='formulario'>
            <label>Email: </label>
            <input type="text" id="email" name="email" value={email} onChange={handleChangeForm}/>
          </div>}

          {(tipoEditar == 'password' ) &&<div className='formulario'>
            <label>Password: </label>
            <input type="text" id="password" name="password" value={password} onChange={handleChangeForm}/>
          </div>} */}

            
            
            <div className='div-botones row'>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral" onClick={()=>submitForm()}>Actualizar</button>
              </div>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral button-secondary" onClick={()=>closeModal()} >Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </Modal>
    </div>
  );
}