import React, { useState, useEffect } from "react"
import {
    Main,
    ContenedorTitulo,
    ContenedorFechas,
} from "./ElementosStyles";
import TextField from "@mui/material/TextField";
import Tabla from "../Historico/Tabla/index"
import Api from "../../services/api-service"
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import MultiSelect from 'react-multiple-select-dropdown-lite';


const Desconexiones = () => {
    const history = useHistory();
    const itemsPerPageOptions = [20, 50, 100, 200];
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [id, setID] = useState("")
    const query = new URLSearchParams(useLocation().search);
    const obteOrganizacion = query.get("organizacion");
    const location = useLocation();
    const [dataFiltrada, setDataFiltrada] = useState([]);

    useEffect(async () => {
        try {
            console.log("esta es la organizacion", obteOrganizacion)
            Api.obtenerDesconexiones(obteOrganizacion).then(
                (respuesta) => {
                    console.log(respuesta)
                    if (respuesta.length > 0) {
                        filtrarData(respuesta);
                    }
                },
                (error) => { }
            );

        } catch (error) {
            console.log(error, 'error');
        }
    }, [location.search, id])

    useEffect(() => {
        setSelectedFilters([])
        setID("")
    }, [location.search])

    let options = [
        { label: 'ID', value: 'id' },
    ]

    const atras = () => {
        history.goBack();
    };

    const filtrarData = (data) => {
        let filtered = data.filter(item => {
            let matchID = true;

            if (selectedFilters.includes('id') && id !== "") {
                matchID = item.idSirena.toLowerCase().includes(id.toLowerCase());
            }

            return matchID;
        });
        setDataFiltrada(filtered);
    };

    const handleOnchange = val => {
        setSelectedFilters(val);

        if (!val.includes('id')) {
            setID('');
        }
    };

    let columnValues = [
        { nColumna: 'ID', nValor: 'idSirena' },
        { nColumna: '# Desconexiones', nValor: 'NumeroDesconexiones' },
        { nColumna: 'Última Desconexión', nValor: 'FechaUltimaDesconexion' },
        { nColumna: 'Acciones', nValor: 'verDesco' },
    ]
    return (
        <Main>
            <ContenedorFechas>
                <Button onClick={atras}>Atras</Button>
            </ContenedorFechas>
            <ContenedorTitulo>Desconexiones de Sirenas</ContenedorTitulo>
            <div style={{ display: "flex", height: "100%" }}>
                <ContenedorFechas>
                    <MultiSelect
                        onChange={handleOnchange}
                        placeholder={"Filtros"}
                        options={options}
                        defaultValue={selectedFilters}
                        closeOnSelect={true}
                    />
                </ContenedorFechas>
                {selectedFilters.includes('id') && (
                    <ContenedorFechas>
                        <TextField
                            label="ID"
                            value={id}
                            onChange={(e) => setID(e.target.value)}
                        />
                    </ContenedorFechas>
                )}
            </div>
            {dataFiltrada.length > 0 ? <Tabla data={dataFiltrada} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnValues} /> : null}
        </Main>
    )
}

export default Desconexiones 
