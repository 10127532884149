import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './styles.css';
import { Actalizacion, Contenedor, Main, NombreD, IconoEdit, StyledReactGridItem, Testing, Titulo } from './ElementsDisplay';
import EditIcon from '@material-ui/icons/Edit';
import '../humedad.css';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CleaningServices } from '@mui/icons-material';

const DisplayTest = forwardRef((props, ref) => {
    const controladorDevice = props.info.viwes.Mdevice || false;
    const controladorNombre = props.info.viwes.Mnombre || false;
    const nombreWidget = props.info.tituloWidget || false;
    const nombeDiposositivo = props.info.nombe || false;
    const idDispositivo = props.idDispositivo || false;
    const background = props.info.background || "#ffffff";
    const colorLetras = props.colorLetras || "#474343";
    const index = props.index;
    const info = props.info;
    const { año, hora } = props?.funcionF(props.info.ultimoDato)


    const handleMouseOver = (event) => {
        event.target.style.cursor = 'pointer';
    }

    const containerRef = useRef(null);
    const [fontSize, setFontSize] = useState("1rem");

    const resizeText = () => {
        const container = containerRef.current;
        if (container) {
            const containerWidth = container.offsetWidth;
            // const containerHeight = container.offsetHeight;
            // const widthFontSize = (containerWidth / props.data.length);
            // const heightFontSize = containerHeight;
            // const newFontSize = `${Math.min(widthFontSize, heightFontSize)-10}px`;
            const newFontSize = `${(containerWidth / 10) + 20}px`; // Cambia esta fórmula según tu preferencia
            setFontSize(newFontSize);

        }
    };
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
    console.log(info);


    useEffect(() => {
        // Llama a resizeText cuando cambie el tamaño del contenedor
        const container = containerRef.current;
        if (container) {
            const observer = new ResizeObserver(resizeText);
            observer.observe(container);
        }

        // Llama a resizeText una vez al cargar la página para establecer el tamaño inicial
        resizeText();

        return () => {
            // Detiene la observación cuando el componente se desmonta
            const container = containerRef.current;
            if (container) {
                const observer = new ResizeObserver(resizeText);
                observer.unobserve(container);
            }
        };
    }, []);

    function mostrarResultado() {
        const agregacion = info.agregacion
        const data = info.data[0].y
        let resultado;
        switch (agregacion) {
            case "Max":
                resultado = Math.max(...data)
                break;
            case "Min":
                resultado = Math.min(...data)
                break;
            case "Total":
                resultado = data.reduce((total, valor) => total + valor, 0);
                break;
            case "Prom":
                const suma = data.reduce((total, valor) => total + valor, 0);
                resultado = suma / data.length;
                break;
        }
        return resultado.toFixed(2)
    }

    return (
        <>
            {console.log(props?.data)}
            {Object.keys(info).length > 0 ? (

                <div
                    style={{

                        textAlign: "center",
                        margin: "auto",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <Main
                        background={background}
                        className='widgets-font'
                    // style={{display:"flex", flexDirection:"column",}}

                    >
                        <IconoEdit style={{ top: 0, right: 0, position: "absolute" }}>
                            <EditIcon onClick={() => props?.funcionE({ index: index, widget: info })} onMouseOver={handleMouseOver} />
                        </IconoEdit>

                        {nombreWidget != false ? <Titulo>{nombreWidget}</Titulo> : null}

                        <Contenedor>
                            <div
                                ref={containerRef}
                                style={{
                                    fontSize: fontSize,
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    // marginTop:"5%"
                                }}
                            >
                                {controladorNombre != false ? <p>{nombeDiposositivo}</p> : null}
                                {controladorDevice != false ? <NombreD>{idDispositivo}</NombreD> : null}
                                {console.log(mostrarResultado())}
                                {mostrarResultado()}
                            </div>
                        </Contenedor>
                        <Actalizacion className='widgets-font'>Ultima actualizacion: {año} {hora}</Actalizacion>
                    </Main>
                </div>

            ) : (<p>No hay datos <ErrorOutlineIcon /></p>)}
        </>
    );
});

export default DisplayTest;
