import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Auth from "../../services/auth-service";
import API from "../../services/api-service";
import {
  Main,
  ContenedorFechas,
  ContenedorDatos,
} from "../Historico/ElementosStyles";
import { Button, Table } from "react-bootstrap";
import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import Select from "../../components/Entradas/Select";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const GenerateReport = () => {
  const [fechaFinal, setFechaFinal] = useState(new Date().getTime());
  const [fechaInicial, setFechaInicial] = useState(
    new Date().getTime() - 1000 * 60 * 60 * 24
  );
  const query = new URLSearchParams(useLocation().search);
  const organizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const organizaciones = Auth.getOrganizaciones();
  const [tipoDispositivo, setTipoDispositivo] = useState([]);
  const [dispositivos, setDispositivos] = useState([]);
  const [selectTipoDispositivo, setSelectTipoDispositivo] = useState("");
  const [dispositivosSeleccionados, setDispositivosSeleccionados] = useState([]);
  const [mostrarFormularioCorreo, setMostrarFormularioCorreo] = useState(false);
  const [email, setEmail] = useState("");
  const [asunto, setAsunto] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [currentDevicesPage, setCurrentDevicesPage] = useState(1);
  const [currentSelectedPage, setCurrentSelectedPage] = useState(1);
  const [checked, setChecked] = useState({
    checkboxPdf: false,
    checkboxExcel: false,
    checkboxMaxPorDia: false,
  });

  const itemsPerPage = 5;
  const totalDevices = dispositivos.length;
  const totalSelectedDevices = dispositivosSeleccionados.length;
  const totalPagesDevices = Math.ceil(totalDevices / itemsPerPage);
  const totalPagesSelected = Math.ceil(totalSelectedDevices / itemsPerPage);

  useEffect(() => {
    setDispositivos([]);
    setDispositivosSeleccionados([]);
  }, [localizacion, organizacion]);

  useEffect(() => {
    organizaciones.forEach((org) => {
      if (org.organizacion === organizacion) {
        org.localizacion.forEach((index) => {
          if (index.localiza === localizacion) {
            const nuevoDispositivos = index.configuraciones.map(
              (dispositivo) => ({
                value: dispositivo.catalogo,
                label: dispositivo.nombre,
              })
            );
            setTipoDispositivo(nuevoDispositivos);
          }
        });
      }
    });
  }, [localizacion]);

  useEffect(() => {
    if (selectTipoDispositivo) {
      API.obtenerTESTING(selectTipoDispositivo, organizacion, localizacion)
        .then((res) => {
          let nuevoDispositivos = [];
          res[0].dispositivos.flatMap((dispositivo) => {
            if (res[0].keys) {
              res[0].keys.map((variable) => {
                nuevoDispositivos = [
                  ...nuevoDispositivos,
                  {
                    id: dispositivo.nombre + variable.variable,
                    tipoDispositivo: selectTipoDispositivo,
                    nombre: dispositivo.nombre,
                    dispositivo: dispositivo.dispositivo,
                    variable: variable.variable,
                    tipoVariable: variable.tipo,
                  },
                ];
              });
            } else {
              nuevoDispositivos = [
                ...nuevoDispositivos,
                {
                  id: dispositivo.nombre,
                  tipoDispositivo: selectTipoDispositivo,
                  nombre: dispositivo.nombre,
                  dispositivo: dispositivo.dispositivo,
                  variable: "null",
                  tipoVariable: "null",
                },
              ];
            }
          });
          setDispositivos(nuevoDispositivos);
        })
        .catch((error) => console.error(error));
    }
  }, [selectTipoDispositivo]);

  const validarCamposReporte = () => {
    if (dispositivosSeleccionados.length === 0) {
      return false;
    }
    return true;
  };

  const validarCamposCorreo = () => {
    if (email === "" || asunto === "" || mensaje === "") {
      return false;
    }
    return true;
  };

  const paginateDevices = (pageNumber) => setCurrentDevicesPage(pageNumber);
  const paginateSelectedDevices = (pageNumber) =>
    setCurrentSelectedPage(pageNumber);

  const handleNextDevices = () => {
    if (currentDevicesPage < totalPagesDevices)
      paginateDevices(currentDevicesPage + 1);
  };

  const handlePreviousDevices = () => {
    if (currentDevicesPage > 1) paginateDevices(currentDevicesPage - 1);
  };

  const handleNextSelected = () => {
    if (currentSelectedPage < totalPagesSelected)
      paginateSelectedDevices(currentSelectedPage + 1);
  };

  const handlePreviousSelected = () => {
    if (currentSelectedPage > 1)
      paginateSelectedDevices(currentSelectedPage - 1);
  };

  const renderPaginationButtons = (
    currentPage,
    totalPages,
    paginate,
    handleNext,
    handlePrevious
  ) => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    if (startPage > 1) {
      buttons.push(
        <Button
          key={1}
          onClick={() => paginate(1)}
          size="sm"
          style={{
            margin: "0 5px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          1
        </Button>
      );
      if (startPage > 2) buttons.push(<span key="dots1">...</span>);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          onClick={() => paginate(i)}
          size="sm"
          style={{
            margin: "0 5px",
            backgroundColor: currentPage === i ? "#007bff" : "#fff",
            color: currentPage === i ? "#fff" : "#007bff",
          }}
        >
          {i}
        </Button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) buttons.push(<span key="dots2">...</span>);
      buttons.push(
        <Button
          key={totalPages}
          onClick={() => paginate(totalPages)}
          size="sm"
          style={{
            margin: "0 5px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          {totalPages}
        </Button>
      );
    }

    return (
      <div>
        <Button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          size="sm"
          style={{
            margin: "0 5px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          &lt;
        </Button>
        {buttons}
        <Button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          size="sm"
          style={{
            margin: "0 5px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          &gt;
        </Button>
      </div>
    );
  };

  const currentDevices = dispositivos.slice(
    (currentDevicesPage - 1) * itemsPerPage,
    currentDevicesPage * itemsPerPage
  );
  const currentSelectedDevices = dispositivosSeleccionados.slice(
    (currentSelectedPage - 1) * itemsPerPage,
    currentSelectedPage * itemsPerPage
  );

  const agregarDispositivo = (dispositivo) => {
    setDispositivosSeleccionados((prev) => [...prev, dispositivo]);
    setDispositivos((prev) => prev.filter((d) => d.id !== dispositivo.id));
  };

  const eliminarDispositivo = (dispositivo) => {
    setDispositivosSeleccionados((prev) =>
      prev.filter((d) => d.id !== dispositivo.id)
    );
  };

  const cambiarFechaI = (fecha) => {
    setFechaInicial(fecha);
  };

  const cambiarFechaF = (fecha) => {
    setFechaFinal(fecha);
  };

  const getData = async () => {
    let data = [];
    const promises = dispositivosSeleccionados.map(async (dispositivo) => {
      return {
        fechaInicial: fechaInicial,
        fechaFinal: fechaFinal,
        organizacion: organizacion,
        localizacion: localizacion,
        intervalo: 10,
        dispositivo: dispositivo,
        maxPorDía: checked.checkboxMaxPorDia,
      };
    });
    const results = await Promise.all(promises);
    data = results.filter((result) => result !== null);
    return data;
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setChecked((prev) => ({ ...prev, [name]: checked }));
  };

  const generarReportePDF = async () => {
    if (validarCamposReporte()) {
      const data = await getData();
      const loadingToast = toast.loading("Cargando...");
      API.getAutomaticReportPdf(data)
        .then((buffer) => {
          const url = window.URL.createObjectURL(
            new Blob([buffer], { type: "application/pdf" })
          );
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte.pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          toast.success("Reporte Exitoso");
        })
        .catch((error) => {
          toast.error("Problemas al generar el Documento");
        })
        .finally(() => {
          toast.dismiss(loadingToast);
        });
    } else {
      toast.error("Agregue al menos un dispositivo");
    }
  };

  const generarReporteExcel = async () => {
    if (validarCamposReporte()) {
      const data = await getData();
      const loadingToast = toast.loading("Cargando...");
      API.getAutomaticReportExcel(data)
        .then((buffer) => {
          const url = window.URL.createObjectURL(
            new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const a = document.createElement("a");
          a.href = url;
          a.download = "reporte_dispositivos.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          toast.success("Reporte Exitoso");
        })
        .catch((error) => {
          toast.error("Problemas al generar el Documento");
        })
        .finally(() => {
          toast.dismiss(loadingToast);
        });
    } else {
      toast.error("Agregue al menos un dispositivo");
    }
  };

  const enviarCorreo = async () => {
    if (validarCamposCorreo()) {
      if (validarCamposReporte()) {
        const info = await getData();
        const data = {
          info: info,
          correo: {
            email: email,
            asunto: asunto,
            mensaje: mensaje,
            reportePdf: checked.checkboxPdf,
            reporteExcel: checked.checkboxExcel,
          },
        };
        const loadingToast = toast.loading("Cargando...");
        API.sendEmailAutomaticReport(data)
          .then((res) => {
            toast.success("Envio Exitoso");
            setMostrarFormularioCorreo(false);
          })
          .catch((error) => {
            toast.error("No se envió el correo");
          })
          .finally(() => {
            toast.dismiss(loadingToast);
          });
      } else {
        toast.error("Agregue al menos un dispositivo");
      }
    } else {
      toast.error("Complete todos los campos del correo");
    }
  };

  return (
    <>
      <Main>
        <Main>
          <ContenedorFechas>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Fecha Inicial"
                value={fechaInicial}
                onChange={cambiarFechaI}
              />
            </LocalizationProvider>
          </ContenedorFechas>
          <ContenedorFechas>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Fecha Final"
                value={fechaFinal}
                onChange={cambiarFechaF}
              />
            </LocalizationProvider>
          </ContenedorFechas>
        </Main>
        <Main>
          <Select
            message="Tipo de Dispositivo"
            data={tipoDispositivo}
            value={selectTipoDispositivo}
            setFunction={setSelectTipoDispositivo}
            id="outlined-disabled"
          />
        </Main>
        {organizacion === "produbanco" ? (
          <Main>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.checkboxMaxPorDia}
                  onChange={handleChange}
                  name="checkboxMaxPorDia"
                />
              }
              label="Obtener unicamente el valor máximo por día"
            />
          </Main>
        ) : (
          <></>
        )}
        <ContenedorDatos>
          <h5>
            <b>Dispositivos Disponibles</b>
          </h5>
          <Table>
            <thead>
              <tr>
                <th>Dispositivo</th>
                <th>Variable</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {currentDevices.map((dispositivo, index) => (
                <tr key={index}>
                  <td>{dispositivo.nombre}</td>
                  <td>{dispositivo.variable}</td>
                  <td>
                    <Button onClick={() => agregarDispositivo(dispositivo)}>
                      Agregar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {renderPaginationButtons(
            currentDevicesPage,
            totalPagesDevices,
            paginateDevices,
            handleNextDevices,
            handlePreviousDevices
          )}
        </ContenedorDatos>
        <ContenedorDatos>
          <h5>
            <b>Dispositivos Seleccionados</b>
          </h5>
          <Table>
            <thead>
              <tr>
                <th>Dispositivo</th>
                <th>Variable</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {currentSelectedDevices.map((dispositivo, index) => (
                <tr key={index}>
                  <td>{dispositivo.nombre}</td>
                  <td>{dispositivo.variable}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => eliminarDispositivo(dispositivo)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {renderPaginationButtons(
            currentSelectedPage,
            totalPagesSelected,
            paginateSelectedDevices,
            handleNextSelected,
            handlePreviousSelected
          )}
        </ContenedorDatos>
        <ContenedorDatos>
          <Button onClick={generarReportePDF} style={{ margin: "0 5px" }}>
            Descargar Reporte PDF
          </Button>
          <Toaster position="top-right" reverseOrder={false} />
          <Button onClick={generarReporteExcel} style={{ margin: "0 5px" }}>
            Descargar Reporte Excel
          </Button>
        </ContenedorDatos>
      </Main>
      <Main>
        <Button
          onClick={() => setMostrarFormularioCorreo(!mostrarFormularioCorreo)}
        >
          {mostrarFormularioCorreo ? "Cancelar" : "Enviar por Correo"}
        </Button>
        {mostrarFormularioCorreo && (
          <>
            <Main>
              <div>
                <TextField
                  label="Correo Electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ margin: "10px 0" }}
                />
              </div>
              <div>
                <TextField
                  label="Asunto"
                  value={asunto}
                  onChange={(e) => setAsunto(e.target.value)}
                  style={{ margin: "10px 0" }}
                />
              </div>
              <div>
                <TextField
                  label="Mensaje"
                  value={mensaje}
                  onChange={(e) => setMensaje(e.target.value)}
                  style={{ margin: "10px 0" }}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.checkboxPdf}
                      onChange={handleChange}
                      name="checkboxPdf"
                    />
                  }
                  label="Reporte PDF"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.checkboxExcel}
                      onChange={handleChange}
                      name="checkboxExcel"
                    />
                  }
                  label="Reporte Excel"
                />
              </div>
            </Main>
            <ContenedorDatos>
              <Button onClick={enviarCorreo}>
                Enviar <SendIcon />
              </Button>
            </ContenedorDatos>
          </>
        )}
      </Main>
    </>
  );
};

export default GenerateReport;
