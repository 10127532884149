import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import API from "../../services/api-service";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import "sweetalert2/src/sweetalert2.scss";
// import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import "./tabla.css";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { ContenedorTablas, TituloDash } from "./DashboardElements";

const useStyles = makeStyles({
  table: {
    minWidth: 600,
  },
});

export default function BasicTable(props) {
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const organizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const classes = useStyles();
  const [action, setAction] = useState("");

  const handleDelete = (event) => {
    console.log(event);
  };

  const handleChange = (event) => {
    setAction(event.target.value);
  };



  const eliminar = (id) => {
    Swal.fire({
      title: "¿Seguro que quieres eliminar este Dashboard?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        API.deletDashboard(organizacion, localizacion, id).then(
          (respuesta) => {
            console.log(respuesta);
            Swal.fire("Dashboard eliminado", "", "success");
            props.recargar()
          },
          (error) => {
            console.log(error);
          }
        );
      } else if (result.isDenied) {
        Swal.fire("Usuario no eliminado", "", "info");
      }
    });
  };

  return (
    <ContenedorTablas>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.columnas.map((columna, key) => (
                <TableCell component="th" scope="row" key={key}>
                  <TituloDash>{columna.label}</TituloDash>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.datos.map((row, key) => (
              <TableRow key={key}>
                <TableCell
                  className="fancy-button"
                  component="th"
                  scope="row"
                  onClick={() => props.actualizarD(row._id)}
                >
                  {row.nombreDasborah}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Stack direction="row" spacing={2}>
                    {/* endIcon={<SendIcon />} */}
                    <Button variant="contained" onClick={props.editarD}>
                      Editar
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      color="error"
                      onClick={() => eliminar(row._id)}
                    >
                      Eliminar
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ContenedorTablas>
  );
}
