import React, { useState, useMemo } from 'react'
import TextField from "@mui/material/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Informa from "../../Informacion/index";
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@mui/material/Button";
import { SketchPicker as Picker } from 'react-color';
import { ContenedorTitulo, Main, Opciones, BotonGuardar } from '../Elements'

const Gauge = ({ data, valorWid, device, variable, variableElegida, showModalVariable, DispositivoE, saveData }) => {
    const [tituloWidget, setTituloWidget] = useState("")
    const [vNombreDispotivo, setVNombreDispotivo] = useState(false)
    const [vIdDispositivo, setVIdDispositivo] = useState(false)
    const [tiempoReporte, setTiempoReporte] = useState(30)
    const [backgroundColor, setBackgroudColor] = useState("#ffffff")
    const [unidad, setUnidad] = useState("")
    const [valorMin, setValorMin] = useState('')
    const [valorMax, setValorMax] = useState('')
    const [checkColor, setColor] = useState(false)

    const GuardarData = () => {
        let Views = {
            "Mdevice": false,
            "Mnombre": false
        }
        let maxY
        let cantidad
        let maxX

        if (Object.keys(data).length == 4) {
            maxY = 0
            cantidad = 1
            data.posiciones = []
            data.identificadores = []
        } else {
            maxY = data.posiciones[0].y;
            maxX = data.posiciones[0].x;
            cantidad = data.posiciones.length + 1;
            cantidad = cantidad.toString();

            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y > maxY) {
                    maxY = data.posiciones[i].y;
                }
            }
            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y == maxY) {
                    if (data.posiciones[i].x > maxX) {
                        maxX = data.posiciones[i].x;
                    }
                }
            }
        }

        if (maxX > 4) {
            maxX = 0
            maxY = maxY + 1
        } else {
            maxX = maxX + 1
        }

        let nombres = {
            name: valorWid,
            device: device,
            variable: variable,
            typeDevice: DispositivoE,
            unidad: unidad,
            valorMin: valorMin,
            valorMax: valorMax,
            reporte: tiempoReporte,
            background: hexColor
        };


        if (tituloWidget != "") {
            nombres.tituloWidget = tituloWidget
        }
        if (vNombreDispotivo) {
            Views.Mnombre = true
        }
        if (vIdDispositivo) {
            Views.Mdevice = true
        }
        nombres.viwes = Views
        let newWidget = {
            w: 6,
            h: 3,
            x: maxX,
            y: maxY,
            i: cantidad,
            moved: false,
            static: false,
        };
        data.posiciones.push(newWidget);
        data.identificadores.push(nombres);
        saveData(data)
        console.log("-----------------------------------")
        console.log(data);
    };


    const VerificarTituloWidget = (e) => {
        const inputValue = e.target.value;
        setTituloWidget(inputValue)

    }

    const handleChange = (event) => {
        setVNombreDispotivo(event.target.checked);
    };

    const handleChangeUnidad = (e) => {
        const inputValue = e.target.value;
        setUnidad(inputValue)
    };

    const handleChangeMin = (e) => {
        const inputValue = e.target.value;
        const regex = /^[0-9\b]+$/;

        if (inputValue === '' || regex.test(inputValue)) {
            setValorMin(inputValue);
        }
    };

    const handleChangeMax = (e) => {
        const inputValue = e.target.value;
        setValorMax(inputValue)
    };

    const handleChangeD = (event) => {
        setVIdDispositivo(event.target.checked);
    };
    const handleChangeC = (event) => {
        setColor(event.target.checked);
    };

    const handleInputChangeddd = (event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9\b]+$/;

        if (inputValue === '' || regex.test(inputValue)) {
            setTiempoReporte(inputValue);
        }
    };

    const decimalToHex = (alpha) =>
        alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

    const hexColor = useMemo(() => {
        if (typeof backgroundColor === 'string') {
            return backgroundColor;
        }
        return `${backgroundColor?.hex}${decimalToHex(backgroundColor?.rgb?.a)}`;
    }, [backgroundColor]);


    return (
        <>
            <Main>
                <ContenedorTitulo>
                    Parámetros
                </ContenedorTitulo>
                <TextField
                    label="Titulo Widget"
                    value={tituloWidget}
                    onChange={VerificarTituloWidget}
                />
                <Opciones>
                    <FormControlLabel
                        control={
                            <Checkbox checked={vNombreDispotivo}
                                onChange={handleChange}
                                name="Nombre del Dispositivo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="Nombre del Dispositivo"
                    /><Informa
                        info="Si desea que el nombre del dispositivo aparezca en el widget, marque la casilla."
                    />
                </Opciones>
                <Opciones>

                    <FormControlLabel
                        control={
                            <Checkbox checked={vIdDispositivo}
                                onChange={handleChangeD}
                                name="ID Disposiivo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="ID Dispositivo"
                    />
                </Opciones>
                <Opciones>
                    <TextField
                        label="Unidad de reporte"
                        value={unidad}
                        onChange={handleChangeUnidad}
                    />
                </Opciones>
                <Opciones>
                    <TextField
                        label="Valor mínimo"
                        value={valorMin}
                        onChange={handleChangeMin}
                    />
                </Opciones>
                <Opciones>
                    <TextField
                        label="Valor máximo"
                        value={valorMax}
                        onChange={handleChangeMax}
                    />
                </Opciones>
                <Opciones>
                    <TextField
                        label="Tiempo de maximo de reporte."
                        value={tiempoReporte}
                        onChange={handleInputChangeddd}
                        helperText="Minutos"
                    /><Informa
                        info="Si dentro del tiempo establecito, el dispositivo no reporte. No saldra datos."
                    />
                </Opciones>
                <Opciones>
                    <FormControlLabel
                        control={
                            <Checkbox checked={checkColor}
                                onChange={handleChangeC}
                                name="Color de fondo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="Color de fondo"
                    /><Informa
                        info="Si desea cambiar el color de fondo del widget marque esta casilla"
                    />
                </Opciones>
                {checkColor != false ?
                    <Opciones>
                        <Picker color={hexColor} onChange={setBackgroudColor} />
                    </Opciones>
                    : null}
                <Opciones>
                    <Button variant="contained" onClick={showModalVariable}>
                        Seleccionar variable
                    </Button>
                </Opciones>

                {Object.keys(variableElegida).length !== 0 ? (
                    <>
                        <h5>Selecciono el dispositivo con la variable ({variableElegida.variable}) del dispositivo ({variableElegida.nombre}) con ID ({variableElegida.deviceID}) </h5>
                    </>
                ) : null}
                {device != null && device != "" ? <BotonGuardar onClick={GuardarData}>Guardar</BotonGuardar> : null}
            </Main>
        </>
    )
}

export default Gauge