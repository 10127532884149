import React from "react";
import "./style.scss";
import { Actalizacion, Contenedor, ContenedorWa, IconoEdit, ImagenWid, IndicadorT, Main, NombreD, StyledReactGridItem, Testing, Titulo } from './ElementsDisplay';
import ReactApexChart from "react-apexcharts";
import hume from "./indicadorhum.png";
//import { Main } from "./ElementsDisplay";
import EditIcon from '@material-ui/icons/Edit';
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";



class radialbartemp extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.value)
        this.eliminarWidget = this.props.funcionE.bind(this);
        this.state = {
            Mnombre: this.props.info.viwes.Mnombre || false,
            Mdevice: this.props.info.viwes.Mdevice || false,
            nombreWidget: this.props.info.tituloWidget || false,
            nombeDiposositivo: this.props.info.nombre || false,
            idDispositivo: this.props.info.device || false,
            series: this.props.seriesData,
            index: this.props.index,
            info: this.props.info,
            background: this.props.info.background,
            año: this.props.funcionF(this.props.info.ultimoDato),


            options: {
                chart: {
                    height: 500,
                    type: 'radialBar',
                    // background:'#000',

                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 105,
                            size: '70%',
                            image: hume,
                            imageWidth: 40,
                            imageHeight: 40,
                            imageClipped: false
                        },
                        dataLabels: {
                            name: {
                                show: false,
                                color: '#333',
                                offsetY: -20,
                            },
                            value: {
                                show: true,
                                color: '#333',
                                offsetY: 45,
                                fontSize: '22px'
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        gradientToColors: ['#071478'],
                        shadeIntensity: 1,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100, 100, 100]
                    },
                },

                stroke: {
                    lineCap: 'round'
                },
                labels: ['Humedad'],
            },


        };

        this.setState({ series: [this.props.seriesData] })

    }

    funcion = () => {
        this.eliminarWidget({ index: this.state.index, widget: this.state.info })
    }

    handleMouseOver = (event) => {
        event.target.style.cursor = 'pointer';
    }
    componentDidUpdate(prevProps) {
        if (prevProps.actualizar !== this.props.actualizar) {
            this.setState({
                Mnombre: this.props.info.viwes.Mnombre || false,
                Mdevice: this.props.info.viwes.Mdevice || false,
                nombreWidget: this.props.info.tituloWidget || false,
                nombeDiposositivo: this.props.info.nombre || false,
                idDispositivo: this.props.info.device || false,
                series: this.props.seriesData,
                index: this.props.index,
                info: this.props.info,
                background: this.props.info.background || "#ffffff",
                options: {
                    chart: {
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 105,
                                size: '70%',
                                image: hume,
                                imageWidth: 40,
                                imageHeight: 40,
                                imageClipped: false
                            },
                            dataLabels: {
                                name: {
                                    show: false,
                                    color: '#333',
                                    offsetY: -20,
                                },
                                value: {
                                    show: true,
                                    color: '#333',
                                    offsetY: 45,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            gradientToColors: ['#071478'],
                            shadeIntensity: 1,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100, 100, 100]
                        },
                    },

                    stroke: {
                        lineCap: 'round'
                    },
                    labels: ['Humedad'],
                },


            });

            console.log("Entro en el nuevoooooasasasooooooo")
        }
    }

    render() {
        return (
            <Main background={this.state.background}>
                <IconoEdit>
                    <EditIcon onClick={this.funcion} onMouseOver={this.handleMouseOver} />
                </IconoEdit>
                <>
                    <Contenedor>
                        {this.state.nombreWidget != false ? <Titulo>{this.state.nombreWidget}</Titulo> : null}
                        {this.state.Mnombre ? <p>{this.state.nombeDiposositivo}</p> : null}
                        {this.props.seriesData != null && this.props.seriesData != "" ? <ReactApexChart options={this.state.options} series={[this.props.seriesData]} type="radialBar" height={220} /> : <p>No han reportado</p>}
                        <NombreD>{this.state.Mdevice ? `ID: ${this.state.idDispositivo}` : null}</NombreD>
                        <Actalizacion className='widgets-font'>Ultima actualizacion: {this.state.año.año} {this.state.año.hora}</Actalizacion>
                    </Contenedor>
                </>
            </Main>
        );
    }
}
export default radialbartemp;