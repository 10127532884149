import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import 'react-pro-sidebar/dist/css/styles.css';
import Top from "../components/Top-admin/Top.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import SidebarMenu from "./sidebarMenu/SidebarMenu";
import './Admin.css';

import routes from "../routes";
import { menu, menuUser } from "../menu";


import Auth from '../services/auth-service.js'



// notificaciones 
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'



function showNotification() {

}

function Admin() {
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => (Auth.getJWT() !== null ? <prop.component {...props} /> : <Redirect from="/" to="/login/ingreso" />)}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <Top />
      <div className="wrapper">
        <ReactNotification />
        <SidebarMenu menu={menu} menuUser={menuUser} />
        <div className="main-panel" ref={mainPanel}>
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
export { showNotification };
