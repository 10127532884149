
import React, { useState, useEffect } from "react";
import AUTH from "../../services/auth-service";
import { useLocation } from "react-router-dom";
import API from "../../services/api-service";
import ImageWithPoints from './ImageWithPoints ';
import ImageWithPointsFav from './ImageWithPointsFavorita';
import toast, { Toaster } from "react-hot-toast";
import TablaV from "./tabla";
import { Button, Card, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import ModalR from "../../components/Modal/Modal right-left/index";
import ModalCentro from "../../components/Modal/Modal Centro";
import { CTituloModal, TituloModal, Opciones, ContenedorFechas } from "./elementsUbicación";


const Index = () => {
  const location = useLocation();
  const [data, setData] = useState([])
  const [dataGate, setDataGate] = useState([])
  const query = new URLSearchParams(useLocation().search);
  const organizacionn = query.get("organizacion");
  const localizacionn = query.get("localizacion");
  const [showEditRango, setShowEditRango] = useState(false);
  const [showEditName, setShowEditName] = useState(false);
  const [showModalCenter, setShowModalCenter] = useState(false);
  const [gateway, setGateway] = useState();
  const [dataArea, setDataArea] = useState();
  const [nombreGateway, setNombreGateway] = useState("");
  const [rol, setRol] = useState()

  const [min1, setMin1] = useState()
  const [max1, setMax1] = useState()
  const [max2, setMax2] = useState()
  const [max3, setMax3] = useState()



  const imageUrl = `https://aiot.constecoin.com/repositorio/${organizacionn}/${localizacionn}/imagen.png`

  const loadData = async () => {
    let organizaciones = AUTH.getOrganizaciones();
    console.log("Leyendo Organizanciones ------")
    console.log(organizaciones)

    let dispositivos = [];

    organizaciones.forEach((index) => {
      if (index.organizacion === organizacionn) {
        index.localizacion.forEach((localizacion) => {
          if (localizacion.localiza === localizacionn) {
            localizacion.configuraciones.forEach((confi) => {
              console.log("condig")
              console.log(confi.catalogo)
              if(localizacionn=="uArtes_loca_1"){
                if(confi.catalogo!="ups_artes_1"&&confi.catalogo!="apc_artes_1"&&confi.catalogo!="attom_artes_1"&&confi.catalogo!="power_artes_1"){
                  dispositivos.push({
                    label: confi.nombre,
                    value: confi.catalogo
                  });
                }
               
              }else{
                dispositivos.push({
                  label: confi.nombre,
                  value: confi.catalogo
                });
              }
            });
          }
        });
      }
    });

    try {
      console.log("Estos son los dispositivos")
      console.log(dispositivos)
      const response = await API.buscarLocalizacion(organizacionn, localizacionn, dispositivos);
      const responseArea = await API.visualizacionAreas();
      setDataArea(responseArea)
      console.log(responseArea)
      console.log("Esta es la data")
      console.log(response)
      if(response.datos[0] !== null){
        setData(response.datos);
        setMin1(response.datos[0].rango.primerNivel.valor1)
        setMax1(response.datos[0].rango.primerNivel.valor2)
        setMax2(response.datos[0].rango.segundoNivel.valor2)
        setMax3(response.datos[0].rango.tercerNivel.valor2)
        setDataGate(response.nombresGateways)
        toast.success("Datos cargados!");
      } else {
        setData([]);
        setMin1(0)
        setMax1(0)
        setMax2(0)
        setMax3(0)
        setDataGate([])
        toast.error("No se encontro Datos!");
      }
    } catch (error) {
      console.error("Error al cargar datos:", error);
      toast.error("Error al cargar datos!");
    }
  };

  useEffect(() => {
    setRol(AUTH.getRol())
    loadData();
  }, [organizacionn, localizacionn]);

  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value) || value === "-";
  };
  const cambiarMin1 = (e) => {
    const inputValue = e.target.value;
    if (isNumeric(inputValue) || inputValue === "") {
      setMin1(inputValue);
    } else {
      toast.error("Por favor, ingresa solo valores numéricos.");
    }
  };

  const cambiarMax1 = (e) => {
    const inputValue = e.target.value;
    if (isNumeric(inputValue) || inputValue === "") {
      setMax1(inputValue);
    } else {
      toast.error("Por favor, ingresa solo valores numéricos.");
    }
  };

  const cambiarMax2 = (e) => {
    const inputValue = e.target.value;
    if (isNumeric(inputValue) || inputValue === "") {
      setMax2(inputValue);
    } else {
      toast.error("Por favor, ingresa solo valores numéricos.");
    }
  };

  const cambiarMax3 = (e) => {
    const inputValue = e.target.value;
    if (isNumeric(inputValue) || inputValue === "") {
      setMax3(inputValue);
    } else {
      toast.error("Por favor, ingresa solo valores numéricos.");
    }
  };

  const showModalEdit = () => {
    setShowEditRango(true)
  }

  const closeModalEdit = () => {
    setShowEditRango(false)
  }

  const showModalName = () => {
    setShowEditName(true)
  }

  const closeModalEditName = () => {
    setShowEditName(false)
    setShowModalCenter(false)
  }

  const openModalEditNombre = (item) => {
    setShowModalCenter(true)
    setGateway(item)
    setNombreGateway(item.nombre)
  }

  const closeModalCenter = () => {
    setShowModalCenter(false)
  }

  const verficiarName = (e) => {
    const inputValue = e.target.value;
    setNombreGateway(inputValue)
  }


  const actualizarData = () => {
    if (
      !min1 ||
      !max1 ||
      !max2 ||
      !max3 ||
      isNaN(min1) ||
      isNaN(max1) ||
      isNaN(max2) ||
      isNaN(max3) ||
      parseFloat(min1) >= parseFloat(max1) ||
      parseFloat(max1) >= parseFloat(max2) ||
      parseFloat(max2) >= parseFloat(max3)
    ) {
      toast.error("Por favor, ingresa valores válidos");
      return;
    }

    if (data[0].rango.primerNivel.valor1 != min1) {
      data[0].rango.primerNivel.valor1 = min1
    }
    if (data[0].rango.primerNivel.valor2 != max1) {
      data[0].rango.primerNivel.valor2 = max1
    }
    if (data[0].rango.segundoNivel.valor1 != max1) {
      data[0].rango.segundoNivel.valor1 = max1
    }
    if (data[0].rango.segundoNivel.valor2 != max2) {
      data[0].rango.segundoNivel.valor2 = max2
    }
    if (data[0].rango.tercerNivel.valor1 != max2) {
      data[0].rango.tercerNivel.valor1 = max2
    }
    if (data[0].rango.tercerNivel.valor2 != max3) {
      data[0].rango.tercerNivel.valor2 = max3
    }



    let Documento = {
      organizacion: organizacionn,
      localizacion: localizacionn,
      rangos: {
        primerNivel: {
          valor1: min1,
          valor2: max1
        },
        segundoNivel: {
          valor1: max1,
          valor2: max2
        },
        tercerNivel: {
          valor1: max2,
          valor2: max3
        },
      }
    }

    API.actualizacionRangosTemperatura(Documento).then((respuesta) => {
      console.log(respuesta)
      if (respuesta.success == true) {
        console.log("ENtro aqui")
        // Swal.fire({ title: "Rango creado/actualizado", icon: "success", confirmButtonColor: "#1976d2", })
        Swal.fire("Guardado exitosamente", "", "success");
        closeModalEdit()
      } else {

      }
    })

  }

  const actualizarNombre = () => {
    let documento = {
      organizacion: organizacionn,
      localizacion: localizacionn,
      nombre: nombreGateway,
      id: gateway.idGateway,
      tipo: "gateways"
    }

    API.actualizarNombreGateway(documento).then((respuesta) => {
      console.log(respuesta)
      if (respuesta.success == true) {
        console.log("ENtro aqui")
        // Swal.fire({ title: "Rango creado/actualizado", icon: "success", confirmButtonColor: "#1976d2", })
        Swal.fire(`${respuesta.message}`, "", "success");
        closeModalCenter()
        loadData()
      } else {

      }
    })
  }

  return (
    <>
      <ModalR isOpen={showEditRango} onClose={closeModalEdit}>
        <CTituloModal style={{
          marginBottom: "40px"
        }}>
          <TituloModal>Editar Rangos Temperatura</TituloModal>
        </CTituloModal>
        <Opciones>
          <ContenedorFechas>Primer nivel</ContenedorFechas>
          <ContenedorFechas>
            <TextField
              label="Min"
              value={min1}
              onChange={cambiarMin1}
              style={{ width: '85px', marginLeft: '17px' }}
            />
          </ContenedorFechas>
          <TextField
            label="Max"
            value={max1}
            onChange={cambiarMax1}
            style={{ width: '85px' }}
          />
        </Opciones>
        <Opciones>
          <ContenedorFechas>Segundo nivel</ContenedorFechas>
          <ContenedorFechas>
            <TextField
              label="Min"
              value={max1}
              onChange={cambiarMax1}
              style={{ width: '85px' }}
            />
          </ContenedorFechas>
          <TextField
            label="Max"
            value={max2}
            onChange={cambiarMax2}
            style={{ width: '85px' }}
          />
        </Opciones>
        <Opciones>
          <ContenedorFechas>Tercer nivel</ContenedorFechas>
          <ContenedorFechas>
            <TextField
              label="Min"
              value={max2}
              onChange={cambiarMax2}
              style={{ width: '85px', marginLeft: '19px' }}
            />
          </ContenedorFechas>
          <TextField
            label="Max"
            value={max3}
            onChange={cambiarMax3}
            style={{ width: '85px' }}
          />
        </Opciones>
        <Button onClick={actualizarData}>
          Actualizar
        </Button>
      </ModalR>
      
      {organizacionn == "favorita" ? (
        <ModalR isOpen={showEditName} onClose={closeModalEditName}>
        <CTituloModal style={{
          marginBottom: "40px"
        }}>
          <TituloModal>Editar Nombres Gateways</TituloModal>
        </CTituloModal>
        <table>
          <thead>
            <tr>
              <th style={{ padding: '10px' }}>ID</th>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>            
            {dataGate.map((item, index) => (
              <tr key={index}>
                <td style={{ padding: '10px' }}>{item.idFavorita}</td>
                <td onClick={() => openModalEditNombre(item)}
                  style={{
                    padding: '10px',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                  }}
                  onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgray'}
                  onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}

                >{item.nombre}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModalR>
      ): null}
      

      <ModalCentro isOpen={showModalCenter} onClose={closeModalCenter}>
        <h4>Actualización nombre </h4>
        <Button style={{ position: "absolute", top: "10px", right: "10px" }} onClick={closeModalCenter}>
          X
        </Button>
        <ContenedorFechas>
          <TextField
            label="Nombre"
            value={nombreGateway}
            onChange={verficiarName}
          />
        </ContenedorFechas>
        <ContenedorFechas>
          <Button className="buttonGeneral" onClick={actualizarNombre}>
            Actualizar
          </Button>
        </ContenedorFechas>
      </ModalCentro>

      <h1 style={{
        textAlign: "center",
        margin: "15px 0px"
      }}>Ubicación de dispositivos</h1>

      {data.length > 0 && (
        <>
          {rol == "administrador" ?
            <Button onClick={showModalEdit} style={{ margin: "0 0 5px 5px" }}>
              Editar Rangos
            </Button> : null
          }


          <TablaV data={data} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {rol == "administrador" && organizacionn=='favorita' ?
              <Button style={{ marginRight: '40px' }} onClick={showModalName}>
                Editar Gateway
              </Button> : null
            }

            {organizacionn == "favorita" ? (
              <ImageWithPointsFav imageUrl={imageUrl} data={data} dataGateways={dataGate} dataArea={dataArea}/>

            ) : <ImageWithPoints imageUrl={imageUrl} data={data} />}
          </div>
        </>

      )}
      <Toaster position="top-center" reverseOrder={false} />
    </>
  )
}

export default Index;
